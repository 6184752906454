import { Component, OnInit,ChangeDetectorRef,Inject } from '@angular/core';
import { FormGroup, FormControl, FormBuilder,ValidatorFn,AbstractControl, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { AdminSettingService } from '../core/adminSetting.service';
import { CurrencyPipe } from '@angular/common';
import { TypedRule } from 'tslint/lib/rules';
import { User } from '../model/user.model';
import { ToastrService } from 'ngx-toastr';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { EditUserComponent } from '../edit-user/edit-user.component';
import { PopupMessage } from '../property/property.component';
import { Messages } from '../Constants/messages';
import { UserDetails } from '../model/UserDetails.model';
import { AddUserComponent } from '../add-user/add-user.component';

@Component({
  selector: 'app-user',
  templateUrl: './user.component.html',
  styleUrls: ['./user.component.css']
})
export class UserComponent implements OnInit {
  users: User;
  userId: number;
  pages: Array<number>;
  pageNo: number = 1;
  type: string='';
  sortCategory: string = "user_id-desc";
  sortType: string ;
  maxElements:number = 10;
  pageData: FormGroup;
  countVal: number;
  isOpen: boolean;
  totalCount: number = 0;
  ownerCount:number=0;
  brokerCount:number=0;
  count1Heading : string = "";
  count2Heading : string = "";
  count1 : number = 0;
  count2 : number = 0
  heading: string = "";
  showListingDropdown = [];
  user: User;
  userDetails: UserDetails;
  noValues: boolean;
  role:string;

  constructor(public dialog: MatDialog,private cdr:ChangeDetectorRef,private cp:CurrencyPipe,private adminSettingService:AdminSettingService, private formBuilder: FormBuilder, private router: Router,private route: ActivatedRoute, private toastr: ToastrService) 
  { 
    this.route.params.subscribe(params => {
      
      if (params['type']) {
        this.type = params['type'];
          this.heading = this.type;
        } else {
          this.heading = "Total";
        }
      this.getUser();
    });

  }

  openAddUserDialog(): void {
    const dialogRef = this.dialog.open(AddUserComponent, {
      width: '800px',
      height: '500px',
    });
    dialogRef.afterClosed().subscribe(result => {
      this.getUser();
      });
  }

   openEditUserDialog(userId): void {
    localStorage.setItem('userId', userId);
    const dialogRef = this.dialog.open(EditUserComponent, {
      width: '800px',
      height: '500px',
      data: {userId}
    });
    dialogRef.afterClosed().subscribe(result => {
    this.getUser();
    });
  }

  ngOnInit() {
    this.countVal = 10;
    this.showListingDropdown = [10, 20, 30, 40, 50, 100];

    this.getUser();

  }
  getUser(){

    this.pageNo = localStorage.getItem('pageNo') ? +localStorage.getItem('pageNo') : 1;
    this.role = this.type;
    this.pageData = this.formBuilder.group({
      pageNo: [localStorage.getItem('pageNo') ? localStorage.getItem('pageNo') :JSON.stringify(1 - 1)],
      sortCategory: [this.sortCategory],
      sortType: [this.sortType],
      role: [this.role],
      userId: [JSON.parse(window.localStorage.getItem('userId'))],
      //roleId: [JSON.parse(window.localStorage.getItem('roleId'))],
      maxElements: [JSON.parse(JSON.stringify(this.maxElements))]
    });

    this.adminSettingService.getUsersListBySort(this.pageData.value)
    .subscribe(data => {
      this.users = data.result;
      this.totalCount = 0;
      this.ownerCount = 0;
      this.brokerCount = 0;

      //alert(this.type)
      if(this.users != null && this.users[0] != null) {
        if (this.type == '' || this.type == null || this.type == 'owner' || this.type == 'broker') {
          switch(this.type){
          case 'owner':
            this.totalCount = this.users[0].ownerCount;
            break;
            case 'broker':
            this.totalCount = this.users[0].brokerCount;
            break;
            default:
            this.totalCount = this.users[0].totalCount;
            this.ownerCount = this.users[0].ownerCount;
            this.brokerCount = this.users[0].brokerCount;
            break;
          }
        }
      }
       this.count1Heading = this.type == "owner"  ? "owner" : "broker";
       this.count2Heading = this.type == "broker"  ? "broker" : "owner";

       this.count1 = this.type == "owner"  ? this.ownerCount : this.brokerCount;
       this.count2 = this.type == "broker" ? this.brokerCount : this.ownerCount;

      this.getPageCount(this.totalCount, this.maxElements);
    });
  }

  getMaxElements(){
    this.maxElements = this.countVal;
    this.pageNo=1;
    this.getUser();
  }
  getPageCount(totalCount, maxElements) {
    var a = Math.ceil(totalCount / maxElements);
    if (a > 0) {
      this.pages = Array(a).fill(1).map((x, i) => i + 1);
      
    }
  }
  getActivePage(page):boolean{
    if(page == this.pageNo) {
      return true;
    } else {
      return false;
    }
  }
  getPageNo(pages) {
    var pageType = pages.split(":")[1];
    var page = pages.split(":")[0];
    switch(pageType){
      case 'prev':
            this.pageNo = this.pageNo>1?this.pageNo-1:this.pageNo;
            break;
      case 'next':
            this.pageNo = this.pageNo<this.pages.length?this.pageNo+1:this.pageNo;
            break;
      default:
            localStorage.setItem('pageNo', page);
            this.pageNo = page;
    }
    
    this.getUser();
  }


  seeUserProperties(userId) {
    this.router.navigate(['/listings'], { queryParams: { userid: userId } });
  }


deleteUser(userId) {
  var propertyId = userId;
  var propertySt = Messages.Delete_User;
  const dialogRef = this.dialog.open(PopupMessage, {
  width: '600px',
  height: '210px',
  data: {propertyId,propertySt}
  });
 dialogRef.afterClosed().subscribe(result => {
   this.userId = result;
   if(this.userId !=null && this.userId != undefined){
     //use for delete the User from the listing
     this.adminSettingService.deleteProperty(userId)
     .subscribe(data => {
       this.toastr.success(Messages.Delete_User_Success);
       this.getUser();
     }, error => {
       this.toastr.error(Messages.Error);
     });
   }
 });
}
}
