import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { FormBuilder, FormGroup, FormControl, Validators, AbstractControl, ValidatorFn } from '@angular/forms';

import { Router } from '@angular/router';
import { HttpParams } from '@angular/common/http';
import { ApiService } from '../core/api.service';
import { ToastrService } from 'ngx-toastr';
import { ApiResponse } from '../model/api.response';
import { MatDialog } from '@angular/material';
import { ForgotPasswordComponent } from '../forgot-password/forgot-password.component';
import { TermsAndConditionsComponent } from '../terms-and-conditions/terms-and-conditions.component';
import { AppComponent } from '../app.component';
import { User } from '../model/user.model';
import { Role } from '../model/role.model';
import { Messages } from '../Constants/messages';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  loginForm: FormGroup;
  addForm: FormGroup;
  response: any;
  rememberMe: boolean = false;
  usernameLogin: string;
  passwordLogin: string;
  isValidEmail: boolean = false;
  users: User;
  role: Role;
  login:boolean = true;
  registration:boolean = false;
  @ViewChild('LoginLeft') LoginLeft:ElementRef;
  LoginData:any;
  height:any;
  height1:any;
  @ViewChild('select') select:ElementRef;


  constructor(private formBuilder: FormBuilder, private router: Router, private apiService: ApiService, private toastr: ToastrService, public dialog: MatDialog, private appcomp: AppComponent) {
    this.addForm = this.formBuilder.group({
      username: new FormControl('', [
        Validators.required,
        this.patternValidator(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)]),
      password: ['', Validators.required],
      confirmPassword: ['', Validators.required],
      roleId: ['', Validators.required],
      firstName: ['', Validators.required],
      lastName: ['', Validators.required],
      termsAndConditions: ['', Validators.requiredTrue]
    }, {
        validator: RegistrationValidator.validate.bind(this)
      });
  }
  openForgotPasswordDialog(): void {
    const dialogRef = this.dialog.open(ForgotPasswordComponent, {
      width: '500px',
      height: '300px'
    });
  }
  openTermsAndConditionsDialog(): void {
    const dialogRef = this.dialog.open(TermsAndConditionsComponent, {
      width: '1000px',
      height: '600px'
    });
  }

  onSubmitforLogin() {
    this.usernameLogin = this.loginForm.controls.usernameLogin.value;
    this.passwordLogin = this.loginForm.controls.passwordLogin.value;
    window.localStorage.setItem('remembered', JSON.stringify(this.rememberMe));

    const body = new HttpParams()
      .set('username', this.loginForm.controls.usernameLogin.value)
      .set('password', this.loginForm.controls.passwordLogin.value)
      .set('grant_type', 'password');
    this.appcomp.showProgressBar(true);
    //user for login
    this.apiService.login(body.toString()).subscribe(data => {
      this.response = JSON.parse(JSON.stringify(data));
      window.localStorage.setItem('username', this.usernameLogin);
      if (JSON.parse(window.localStorage.getItem('remembered'))) {
        window.localStorage.setItem('password', this.passwordLogin);
      }
      window.localStorage.setItem('token', this.response.access_token);
      //use user and user details by user name
      this.apiService.getUserByUsername(window.localStorage.getItem('username'))
      .subscribe(data => {
        this.appcomp.showProgressBar(false);
        this.users = data.result;
        this.role = this.users.role;
        
        if(this.role.roleId!=1){
        this.router.navigate(["listings"]);
        }else{
          this.router.navigate(["settings"]);
        }
        
        window.localStorage.setItem('roleId', JSON.stringify(this.role.roleId));
        window.localStorage.setItem('userId', JSON.stringify(this.users.userId));
  
      });
      
    }, error => {
      this.appcomp.showProgressBar(false);
      if (error.error.error_description == "Bad credentials") {
        this.toastr.error(Messages.Bad_Credentials);
      } else if (error) {
        if(error.name=="HttpErrorResponse"){
          this.toastr.error(Messages.Error);
        }
        else{
        this.toastr.error(Messages.Inactive_Account);
        }
      } else {
        this.toastr.error(error.error.error_description);
      }
    });
  }


  apiResponse = new ApiResponse;
  onSubmitforAdd() {
    if (this.addForm.invalid) {
      return;
    }
    this.appcomp.showProgressBar(true);
    //use for create the user and save the user in data base
    this.apiService.createUser(this.addForm.value)
      .subscribe(data => {
        this.apiResponse = data;
        this.appcomp.showProgressBar(false);
        if (this.apiResponse.result.isDuplicate === "Duplicate username") {
          this.toastr.error(Messages.Already_Registered);
        } else {
          this.toastr.success(Messages.Register_Success);
          this.addForm.reset();
          this.router.navigate(['login']);
        }
      });
  }

  ngOnInit() {
    
    if (window.localStorage.getItem('token') != null) {
      this.router.navigate(['listings']);
    }
    else {
      if (JSON.parse(window.localStorage.getItem('remembered'))) {
        this.usernameLogin = window.localStorage.getItem('username');
        this.passwordLogin = window.localStorage.getItem('password');
        this.rememberMe = JSON.parse(window.localStorage.getItem('remembered'));
      } else {
        this.usernameLogin = '';
        this.passwordLogin = '';
      }
      // validate the form  fields
      this.loginForm = this.formBuilder.group({
        usernameLogin: new FormControl('', [
          Validators.required,
          this.patternValidator(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)]),
        passwordLogin: ['', Validators.required],
        rememberMe: ['', Validators.nullValidator]
      });
    }
  }
  
  //user for user name validation email formate
  patternValidator(regexp: RegExp): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } => {
      const value = control.value;
      if (value == '' || value == null) {
        this.isValidEmail = false;
        return null;
      }
      if (!regexp.test(value)) {
        this.isValidEmail = true;
        return { 'patternInvalid': { regexp } };
      }
      else {
        this.isValidEmail = false;
        return null;
      }
    };
  }
  LoginR(v){
    this.height = (window.innerHeight);
    setTimeout(()=>{
      this.LoginData = this.LoginLeft.nativeElement.clientHeight;
      if(this.LoginData > this.height){
        this.height1 = this.LoginData + 'px';
      }
      else{
        this.height1 = 100 +'%';
      }
     }, 10);
      
    if(v == 1){
      this.login = true;
      this.registration = false;
    }
    if(v == 2){
      this.login = false;
      this.registration = true;
    }
  }
}

//user for validate the password matching and missmatching
export class RegistrationValidator {
 
  static validate(loginForm: FormGroup) {
    let password = loginForm.controls.password.value;
    let confirmPassword = loginForm.controls.confirmPassword.value;
    if (confirmPassword == '' || confirmPassword == null) {
      return null;
    }
    if (confirmPassword !== password) {
      return {
        doesMatchPassword: true
      };
    }
    return null;

  }

  
}
