import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import {Common} from '../../common';
import { SidebarMomentService } from '../../core/sidebar/slidebar.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {
  
  signin:string;

  constructor(private common : Common, private sidebarMomentService:SidebarMomentService) { }


  ngOnInit() {
    if(window.localStorage.getItem('token')!=null){
      this.signin="Logout";
    }
    else{
      this.signin="Sign in";
    }
  }

  slideMe(){
    this.sidebarMomentService.toggle();
  }}