import { Component, OnInit, ViewChild, ElementRef, Input, EventEmitter, Output } from '@angular/core';
import { SidebarMomentService } from '../core/sidebar/slidebar.service';
import { Common } from '../common';
import { ActivatedRoute } from '@angular/router';
import { HomeComponent } from '../home/home.component';


@Component({
  selector: 'app-listing-header',
  templateUrl: './Listing-header.component.html',
  styleUrls: ['./Listing-header.component.css']
})
export class ListingHeaderComponent implements OnInit {
  signin : string;
  @ViewChild('header') header:ElementRef;
  @Output() 
  sendMsgEvent = new EventEmitter<number>();
  headername:number;
  userId: string;
  listingPage:boolean;
  constructor(private route:ActivatedRoute, private common : Common, private sidebarMomentService:SidebarMomentService){
  
  
  }

  ngOnInit() {
    let propId = +this.route.snapshot.paramMap.get('propertyId');
    if(propId!=0){
      this.listingPage=true;
    }
    this.userId = window.localStorage.getItem('userId');

    if(window.localStorage.getItem('token')!=null){
      this.signin="Logout";
    }
    else{
      this.signin="Sign in";
    }
   this.headername = this.header.nativeElement.clientHeight;
   this.sendMsg();
  }
  sendMsg() {
    this.sendMsgEvent.emit(this.headername);
  }
  
  slideMe(){
    this.sidebarMomentService.toggle();
  }

  back() {
    window.history.back();
  }

  // openPopup(type){
  //   var propertyTypeId = this.propId;
  //   var searchFieldVal = this.serachVal;
  //   const dialogRef = this.dialog.open(DetailedSearchComponent,{
  //     width:'80%',
  //     height:'60%',
  //     data: {type,propertyTypeId,searchFieldVal}
  //   });
  //   dialogRef.afterClosed().subscribe(result => {
  //     console.log(result);
  //     this.detailedSearch = result;
  //     this.propId = this.detailedSearch.propertyTypeId;
  //     this.serachVal = this.detailedSearch.searchFieldVal;
     
  //   })  
  // }

}

