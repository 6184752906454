import { Component, OnInit, ViewChild, ElementRef, Output, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import { HomeComponent } from '../../home/home.component';
import { VALID_ATTRS } from '@angular/core/src/sanitization/html_sanitizer';
import { MatDialog } from '@angular/material';
import { ContactComponent } from '../../contact/contact.component';
import { PrivacyPolicy, TermsAndConditionsComponent } from '../../terms-and-conditions/terms-and-conditions.component';
import { AboutUsComponent } from '../../about-us/about-us.component';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent {
  @ViewChild('footer') footer: ElementRef;
  @Output()
  sendMsgEvent = new EventEmitter<number>();
  headername: number;
  constructor(private dialog: MatDialog, private router: Router, private home: HomeComponent) { }

  sendMsg() {
    this.sendMsgEvent.emit(this.headername);
  }
  ngAfterViewInit() {
    this.headername = this.footer.nativeElement.clientHeight;
    this.sendMsg();
  }
  redirect(id, type) {
    window.scroll(0, 0);
    if (id == 1) {
      this.router.navigate(['home/' + type]);
    }
    else {
      this.router.navigate([type]);
    }
  }
  openPopup(id): void {
    switch (id) {
      case 1:
        this.dialog.open(ContactComponent, { width: '70%', height: '80%' });
        break;
      case 2:
        this.dialog.open(PrivacyPolicy, { width: '70%', height: '90%' });
        break;
      case 3:
        this.dialog.open(TermsAndConditionsComponent, { width: '70%', height: '90%' });
        break;
      default:
        this.dialog.open(AboutUsComponent, { maxWidth: '945px', width: '100%', height: 'auto' });
        break;
    }
  }
}
