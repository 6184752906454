import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { User } from "../model/user.model";
import { Observable } from "rxjs/index";
import { ApiResponse } from "../model/api.response";
import { environment } from '../../environments/environment';
import { Role } from '../model/role.model';

@Injectable()
export class ApiService {

  constructor(private http: HttpClient) { }
  apiBaseUrl: string = environment.apiHost;

  login(loginPayload): Observable<ApiResponse> {
    const headers = {
      'Authorization': 'Basic ' + btoa('crelps-client:crelps-secret'),
      'Content-type': 'application/x-www-form-urlencoded',
    }
    return this.http.post<ApiResponse>(this.apiBaseUrl + 'oauth/token', loginPayload, { headers });
  }

  getUsers(): Observable<ApiResponse> {
    const headers = {
      'Authorization': 'Basic ' + btoa('crelps-client:crelps-secret'),
      'Content-type': 'application/x-www-form-urlencoded',
    }
    return this.http.get<ApiResponse>(this.apiBaseUrl + 'users?access_token=' + window.localStorage.getItem('token'), { headers });
  }
  getUserByUsername(username): Observable<ApiResponse> {
    const headers = {
      'Authorization': 'Basic ' + btoa('crelps-client:crelps-secret'),
      'Content-type': 'application/x-www-form-urlencoded',
    }
    const token = window.localStorage.getItem('token');
    return this.http.get<ApiResponse>(this.apiBaseUrl + 'users/user/' + username + '?access_token=' + token, { headers });

  }

  createUser(user: User): Observable<ApiResponse> {

    const headers = {
      'Authorization': 'Basic ' + btoa('crelps-client:crelps-secret'),
      'Content-type': 'application/json',
    }

    return this.http.post<ApiResponse>(this.apiBaseUrl + 'users', JSON.stringify(user), { headers });
  }

  changePassword(user: User): Observable<ApiResponse> {
    const headers = {
      'Authorization': 'Basic ' + btoa('crelps-client:crelps-secret'),
      'Content-type': 'application/json',
    }
    return this.http.post<ApiResponse>(this.apiBaseUrl + 'users/changePassword', JSON.stringify(user), { headers });
  }
  passwordChange(user:User): Observable<ApiResponse> {
    const headers = {
      'Authorization': 'Basic ' + btoa('crelps-client:crelps-secret'),
      'Content-type': 'application/json',
    }
    const token = window.localStorage.getItem('token');
    return this.http.post<ApiResponse>(this.apiBaseUrl + 'users/passwordChange?access_token='+token, user, { headers });
  }

  forgotPassword(username): Observable<ApiResponse> {
    const headers = {
      'Authorization': 'Basic ' + btoa('crelps-client:crelps-secret'),
      'Content-type': 'application/json',
    }
    return this.http.get<ApiResponse>(this.apiBaseUrl + 'users/forgotPassword/' + username, { headers });
  }

  getMenusByRoleId(roleId): Observable<ApiResponse> {
    const headers = {
      'Authorization': 'Basic ' + btoa('crelps-client:crelps-secret'),
      'Content-type': 'application/json',
    }
    return this.http.get<ApiResponse>(this.apiBaseUrl + 'roles/getMenu?roleId=' + roleId, { headers });
  }
  updateUser(data:FormData): Observable<ApiResponse> {
    const headers = {
      'Authorization': 'Basic ' + btoa('crelps-client:crelps-secret'),
    }
    return this.http.post<ApiResponse>(this.apiBaseUrl + 'users/update?acces_token=' + window.localStorage.getItem('token'), data, { headers });
  }
  
}
