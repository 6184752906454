import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ToastrModule, ToastContainerDirective, ToastContainerModule } from 'ngx-toastr';

import { AppComponent } from './app.component';
import { LoginComponent } from './login/login.component';
import { ApiService } from "./core/api.service";
import { HTTP_INTERCEPTORS, HttpClientModule } from "@angular/common/http";
import { ReactiveFormsModule } from "@angular/forms";
import { routing } from "./app.routing";
import { TokenInterceptor } from "./core/interceptor";
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { ChangePasswordComponent } from './change-password/change-password.component';
import { MaterialModule } from './material.module';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { HomeComponent } from './home/home.component';
import { TermsAndConditionsComponent } from './terms-and-conditions/terms-and-conditions.component';
import { SidebarMomentService } from './core/sidebar/slidebar.service';
import { ProfileComponent } from './profile/profile.component';
import { MatSelectModule } from '@angular/material/select'
import { Common } from './common';
import { PasswordChangeComponent } from './password-change/password-change.component';
import { PropertyModule } from './property/property.module';
import { CommonComponentModule } from './common-component/common-component.module';
import { SearchPropertyService } from './core/SearchProperty.service';
import { SearchListComponent } from './search-list/search-list.component';
import { ListingComponent } from './listing/listing.component';
import { SliderModule } from 'angular-image-slider';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { ContactComponent } from './contact/contact.component';
import { AgmCoreModule } from '@agm/core';
import { AdminSettingService } from './core/adminSetting.service';
import { BillingService } from './core/billing.service';
import { DetailedSearchComponent } from './detailed-search/detailed-search.component';
import { DetailedSearch } from './model/detailed-search.model';
import { UserComponent } from './user/user.component';
import { PhonePipe } from './phone-pipe.component';
import { AddUserComponent } from './add-user/add-user.component';

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    ForgotPasswordComponent,
    ChangePasswordComponent,
    HomeComponent,
    TermsAndConditionsComponent,
    ProfileComponent,
    PasswordChangeComponent,
    Common,
    SearchListComponent,
    ListingComponent,
    PageNotFoundComponent,
    ContactComponent,
    DetailedSearchComponent,
    AddUserComponent
  ],
  imports: [
    CommonModule,
    BrowserAnimationsModule, // Required for custom alerts
    ToastrModule.forRoot(), // Required for custom alerts
    MaterialModule, // Required for Pop up
    MatProgressSpinnerModule,
    MatSelectModule,
    BrowserModule,
    routing,
    ReactiveFormsModule,
    HttpClientModule,
    ToastContainerModule,
    PropertyModule,
    CommonComponentModule,
    SliderModule,
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyD2_EN13Xykp0MtKHq6YDSLR0wAvVKG-Yw'
    })
  ],
  providers: [ApiService, SidebarMomentService, Common,HomeComponent, SearchPropertyService,AdminSettingService,BillingService,DetailedSearch, UserComponent, PhonePipe,Common, {
    provide: HTTP_INTERCEPTORS,
    useClass: TokenInterceptor,
    multi: true
  }],
  bootstrap: [AppComponent],
  entryComponents: [DetailedSearchComponent]

})
export class AppModule { }
