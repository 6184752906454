import { NgModule }             from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { PropertyComponent } from './property.component';
import { AddPropertyComponent } from './add-property/add-property.component';
import { SettingsComponent } from '../settings/settings.component';
import { UserComponent } from '../user/user.component';
import { EditUserComponent } from '../edit-user/edit-user.component';
import { BillingComponent } from '../billing/billing.component';

const propertyRoutes: Routes = [
  {
    path: 'listings',
    children : [
      {
        path:":type",
        component: PropertyComponent
      },
      {
        path:"**",
        component: PropertyComponent
      }
      
    ]
  },
  { path: 'property/:id', component: AddPropertyComponent},
  // { path: 'listings/:type', component: PropertyComponent},
  { path: 'user',  component: UserComponent},
  { path: 'user/:type',  component: UserComponent},
  { path: 'settings',  component: SettingsComponent},
  { path: 'edit-user',  component: EditUserComponent},
  { path: 'billing',  component: BillingComponent},
];
@NgModule({
  imports: [

    RouterModule.forChild(propertyRoutes)
  ],
  exports: [
    RouterModule
  ]
})
export class PropertyRoutingModule { }