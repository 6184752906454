import { Component, OnInit, ViewChild, ElementRef, Output, EventEmitter} from '@angular/core';
import { SidebarMomentService } from '../../core/sidebar/slidebar.service';
import {Common} from '../../common';
import { MatDialog } from '@angular/material';
import { UserDetails } from '../../model/UserDetails.model';
import { ApiService } from '../../core/api.service';
import { User } from '../../model/user.model';
import { AfterViewInit } from '@angular/core';

@Component({
  selector: 'app-dashboard-header',
  templateUrl: './dashboard-header.component.html',
  styleUrls: ['./dashboard-header.component.css']
})
export class DashboardHeaderComponent implements OnInit, AfterViewInit {
   hide:boolean = false;
   searchBox : boolean = true;
   mobileSearchBox : string;
   @ViewChild('dashHeader') dashHeader:ElementRef;
   @Output() 
   sendMsgEvent = new EventEmitter<number>();
   headername:number;
   username:string=window.localStorage.getItem('username');
  constructor(private apiService:ApiService,private common : Common, private sidebarMomentService:SidebarMomentService, private dialog:MatDialog) { }
  
  userDetails:UserDetails;
  user:User;

  ngOnInit() {
    this.user=new User();
    this.userDetails=new UserDetails();
    this.apiService.getUserByUsername(window.localStorage.getItem('username'))
    .subscribe(data => {
      this.user = data.result;
       this.userDetails= data.result.userDetails;
      }
    );
  
  }
  slideMe(){
    this.sidebarMomentService.toggle();
  }
  dropDown(){
    this.hide = !this.hide;
  }
  searchBar() {
    this.searchBox = !this.searchBox;
    this.mobileSearchBox = "";
    if (this.searchBox == false) {
      this.mobileSearchBox = "mobile-search-box";
    }
  }
  sendMsg() {
    this.sendMsgEvent.emit(this.headername);
  }
  ngAfterViewInit(){
    this.headername = this.dashHeader.nativeElement.clientHeight;
    this.sendMsg();
  }
}
