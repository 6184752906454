import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material';
import { ProfileComponent } from './profile/profile.component';
import { PasswordChangeComponent } from './password-change/password-change.component';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class Common {

  @ViewChild('Dash') Property:ElementRef;
    constructor(private router:Router, private dialog:MatDialog) { }

  logout(){
    if(window.localStorage.getItem('token')!=null){
      window.localStorage.removeItem('token');
      window.localStorage.removeItem('userId');
      window.localStorage.removeItem('roleId');
      window.localStorage.removeItem('pageNo');
        if (!JSON.parse(window.localStorage.getItem('remembered'))) {
        window.localStorage.removeItem('username');
        window.localStorage.removeItem('password');
        }
    }
    this.router.navigate(['login']);
  }
  openProfile(): void {
    const dialogRef = this.dialog.open(ProfileComponent, {
      width: '1000px',
      height: '600px'
    });
  }
  openPasswordChange(){
    const dialog =this.dialog.open(PasswordChangeComponent,{
      width:'800px',
      height:'500px'
    })
  }

}