import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest } from '@angular/common/http';
import { Observable } from "rxjs/index";
import { ApiResponse } from "../model/api.response";
import { environment } from '../../environments/environment';
import { json } from '@angular-devkit/core';
import { Billing } from '../model/billing.model';

@Injectable()
export class BillingService {
    constructor(private http: HttpClient) { }
    apiBaseUrl: string = environment.apiHost;
 //save the billing details in data base
  saveBillingDetails(billingData: Billing): Observable<ApiResponse> {
  return this.http.post<ApiResponse>(this.apiBaseUrl + 'billing', billingData);
}
}