export enum Messages{
    Password_Update = 'Password updated successfully',
    Contact_Success = 'Thank you for contacting us, will get back to you as soon as possible.',
    Contact_Failure = 'Sorry, message sending failed. Please try again.',
    Forgot_Password_Success = 'Mail has been sent to registered email id to change password',
    Bad_Credentials = 'Invalid credentials, Username or password does not match',
    Error = 'Oops Something Wrong! Please try again',
    Inactive_Account = 'User Account not active',
    Already_Registered = 'User Already Registered!',
    Register_Success = 'Please check your email to confirm and complete your registration.',
    Old_Password_Incorrect = 'Old Password is incorrect',
    Password_Update_Successfull = 'Password updated successfully',
    UserDetails_Update_Successfull = 'User details updated Successfully',
    Image_Type_Error = 'Image should be of jpg, jpeg or png type.',
    Size_Error = 'Each File should be less than 5 MB of size.',
    Attachment_Size_Error = 'Each File should be less than 10 MB of size.',
    Delete_Property_Image = 'Are you sure want to delete this property image?',
    DefaultImage_Error = 'You can\'t remove default Image',
    ImageRemove_Success = 'Image removed',
    ImageLimit = 'You Can\'t Upload More than 5 Images',
    Delete_Property_Attachment = 'Are you sure want to delete this property File?',
    AttachmentRemove_Success = 'Image removed',
    AttachmentLimit = 'You Can\'t Upload More than 5 Images',
    Document_Type_Error = 'Document should be of pdf type.',
    Property_Delete_Success = 'Property Deleted Successfully',
    Property_Delete = 'Are you sure want to delete the property?',
    Property_Status_Update = 'Property status updated successfully!',
    Property_ActiveToDraft = 'Are you sure you want to change the status of this property to draft?',
    Property_DraftToActive = 'Are you sure you want to change the status of this property to active?',
    AdminDetails_Success = 'Admin Details Created Succesfully',
    Sample_Image_Path = 'assets/images/table-img.jpg',
    Delete_User = 'Are you sure want to delete the User? Property will also be deleted if you delete the user.',
    Delete_User_Success = 'User Deleted Successfully',
    Delete_Company_Logo = 'Are you sure want to delete Company Logo?',
    Comapny_Logo_Removed = 'Company Logo Removed'
}