import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { ApiService } from '../core/api.service';
import { ToastrService } from 'ngx-toastr';
import { MatDialogRef } from '@angular/material';
import { HttpParams } from '@angular/common/http';
import { User } from '../model/user.model';
import { Messages } from '../Constants/messages';

@Component({
  selector: 'app-password-change',
  templateUrl: './password-change.component.html',
  styleUrls: ['./password-change.component.css']
})
export class PasswordChangeComponent {

  invalidLogin: boolean = false;
  showLogin: Boolean = false;
  changePasswordForm: FormGroup;

  constructor(private formBuilder: FormBuilder, private router: Router, private apiService: ApiService, private route: ActivatedRoute, private toastr: ToastrService, private dialogRef: MatDialogRef<PasswordChangeComponent>) {
    this.changePasswordForm = this.formBuilder.group({
      userId: [JSON.parse(window.localStorage.getItem('userId'))],
      oldPassword: ['', Validators.required],
      password: ['', Validators.required],
      confirmPassword: ['', Validators.required]
    }, {
        validator: RegistrationValidator.validate.bind(this)
      });
  }

  onXClick(): void {
    this.dialogRef.close();
  }

  onSubmit() {
    this.apiService.passwordChange(this.changePasswordForm.value).subscribe(data => {
      if (data.message == "failure") {
        this.toastr.error(Messages.Old_Password_Incorrect);
      } else {
        this.dialogRef.close();
        this.toastr.success(Messages.Password_Update_Successfull);
      }
    }, error => {
      this.toastr.error(error.error.error.description);
    }
    );
  }
}

export class RegistrationValidator {
  static validate(changePasswordForm: FormGroup) {
    let oldPassword = changePasswordForm.controls.oldPassword.value;
    let password = changePasswordForm.controls.password.value;
    let confirmPassword = changePasswordForm.controls.confirmPassword.value;
    if(password != '' && oldPassword != '' && password == oldPassword && password != null && oldPassword != null){
      return {
        samePassword: true
      };
      
    }
    if (confirmPassword == '' || confirmPassword == null) {
      return null;
    }
    if (confirmPassword !== password) {
      return {
        doesMatchPassword: true
      };
    }
    return null;

  }
}
