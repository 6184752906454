import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest } from '@angular/common/http';
import { Observable } from "rxjs/index";
import { ApiResponse } from "../model/api.response";
import { environment } from '../../environments/environment';
import { json } from '@angular-devkit/core';
import { Property } from '../model/property.model';


@Injectable()
export class PropertyService {

  constructor(private http: HttpClient) { }
  apiBaseUrl: string = environment.apiHost;

  createProperty(data): any {
    const req = new HttpRequest(
      'POST',
      this.apiBaseUrl + 'property?access_token='+window.localStorage.getItem('token'),
      data,
      {
        reportProgress: true,
        responseType: 'text'
      }
    );
    return this.http.request(req);
  }

  getPropertyTypeList(): Observable<ApiResponse> {
    const headers = {
      'Authorization': 'Basic ' + btoa('crelps-client:crelps-secret'),
      'Content-type': 'application/json',
    }
    return this.http.get<ApiResponse>(this.apiBaseUrl + 'property/getPropertyType', { headers });
  }

  getPropertySubTypeList(typeId: number): Observable<ApiResponse> {
    const headers = {
      'Authorization': 'Basic ' + btoa('crelps-client:crelps-secret'),
      'Content-type': 'application/json',
    }
    return this.http.get<ApiResponse>(this.apiBaseUrl + 'property/getPropertySubType/' + typeId, { headers })
  }
  
  getProperty(userId): Observable<ApiResponse> {
    const headers = {
      'Authorization': 'Basic ' + btoa('crelps-client:crelps-secret'),
      'Content-type': 'application/json',
    }
    return this.http.get<ApiResponse>(this.apiBaseUrl + 'property/' + userId+'?access_token='+window.localStorage.getItem('token'), { headers });
  }

  getPropertyListById(propertyId): Observable<ApiResponse> {
    const headers = {
      'Authorization': 'Basic ' + btoa('crelps-client:crelps-secret'),
      'Content-type': 'application/json',
    }
    return this.http.get<ApiResponse>(this.apiBaseUrl + 'property/getProperty/' + propertyId+'?access_token='+window.localStorage.getItem('token'), { headers });
  }

    updateProperty(data: Property): Observable<ApiResponse> {
    const headers = {
      'Authorization': 'Basic ' + btoa('crelps-client:crelps-secret'),
      'Content-type': 'application/json',
    }
    return this.http.post<ApiResponse>(this.apiBaseUrl + 'property/updateProperty?access_token='+window.localStorage.getItem('token'), data, { headers });
  }
  
  deleteProperty(propertyId): Observable<ApiResponse> {
    const headers = {
      'Authorization': 'Basic ' + btoa('crelps-client:crelps-secret'),
      'Content-type': 'application/json',
    }
    return this.http.delete<ApiResponse>(this.apiBaseUrl + 'property/delete/' + propertyId + "?access_token=" + window.localStorage.getItem('token'), { headers });
  }
  
  getListingByType(data): Observable<ApiResponse> {
   let propertyListUrl = this.apiBaseUrl + 'property/getProperties/?access_token=' + window.localStorage.getItem('token');
    const headers = {
      'Authorization': 'Basic ' + btoa('crelps-client:crelps-secret'),
      'Content-type': 'application/json',
    }
    return this.http.post<ApiResponse>(propertyListUrl, data, { headers });
  }
  
  postListing(propertyId): Observable<ApiResponse> {
    const headers = {
      'Authorization': 'Basic ' + btoa('crelps-client:crelps-secret'),
      'Content-type': 'application/json',
    }
    return this.http.get<ApiResponse>(this.apiBaseUrl + 'property/getProperties/'+propertyId+ "?access_token=" + window.localStorage.getItem('token'), { headers });
  }
  
  deleteGallery(galleryId):Observable<ApiResponse> {
    const headers = {
      'Authorization': 'Basic ' + btoa('crelps-client:crelps-secret'),
      'Content-type': 'application/json',
    }
    return this.http.delete<ApiResponse>(this.apiBaseUrl + 'property/deleteGallery/'+galleryId+ "?access_token=" + window.localStorage.getItem('token'), { headers });
  }
  deleteAttachment(attachmentId):Observable<ApiResponse> {
    const headers = {
      'Authorization': 'Basic ' + btoa('crelps-client:crelps-secret'),
      'Content-type': 'application/json',
    }
    return this.http.delete<ApiResponse>(this.apiBaseUrl + 'property/deleteAttachment/'+attachmentId+ "?access_token=" + window.localStorage.getItem('token'), { headers });
  }
  addAttachmentDescription(attachmentId, attachmentName): Observable<ApiResponse> {
    const headers = {
      'Authorization': 'Basic ' + btoa('crelps-client:crelps-secret'),
      'Content-type': 'application/json',
    }
    return this.http.get<ApiResponse>(this.apiBaseUrl + 'property/renameAttachment?attachmentId='+attachmentId+"&attachementDescription="+attachmentName+ "&access_token=" + window.localStorage.getItem('token'), { headers });
  }
  deleteCompanyLogo(propertyId:number): Observable<ApiResponse> {
    const headers = {
      'Authorization': 'Basic ' + btoa('crelps-client:crelps-secret'),
      'Content-type': 'application/json',
    }
    return this.http.get<ApiResponse>(this.apiBaseUrl + 'property/removeCompanyLogo?propertyId='+propertyId+ "&access_token=" + window.localStorage.getItem('token'), { headers });
  }
    getPropertyTypeListBySaleLease(saleLeaseType): Observable<ApiResponse> {
    return this.http.get<ApiResponse>(this.apiBaseUrl + 'property/getType/' +saleLeaseType);
  }

  getPropertyTypeAndSubtype(): Observable<ApiResponse> {
    return this.http.get<ApiResponse>(this.apiBaseUrl + 'property/getPropertyTypeAndSubtype/');
  }

  getStateList(): Observable<ApiResponse> {

    return this.http.get<ApiResponse>(this.apiBaseUrl + 'property/getState');
  }
}
