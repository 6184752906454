
import { FormBuilder, FormGroup, FormControl, Validators, AbstractControl, ValidatorFn } from '@angular/forms';
import { MatDialog } from '@angular/material';
import { MatDialogRef } from '@angular/material';
import { Router } from "@angular/router";
import { ApiService } from "../core/api.service";
import { ApiResponse } from '../model/api.response';
import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { ChangeDetectorRef } from '@angular/core';
import { Messages } from '../Constants/messages';

@Component({
  selector: 'app-add-user',
  templateUrl: './add-user.component.html',
  styleUrls: ['./add-user.component.css']
})
export class AddUserComponent implements OnInit {
  addForm: FormGroup;
  roleId: number;
  isValidEmail: boolean = false;
  progressBar: boolean;

  marked = false;
  termsAndConditions = false;

  constructor(private formBuilder: FormBuilder,private cdr: ChangeDetectorRef,private dialogRef: MatDialogRef<AddUserComponent>, private toastr: ToastrService,private router: Router, private apiService: ApiService, public dialog: MatDialog) {
    this.addForm = this.formBuilder.group({
      username: new FormControl('', [
        Validators.required,
        this.patternValidator(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)]),
      password: ['', Validators.required],
      confirmPassword: ['', Validators.required],
      roleId: [''],
      firstName: ['', Validators.required],
      lastName: ['', Validators.required],

    }, {
        validator: RegistrationValidator.validate.bind(this)
      });
  }

  onXClick(): void {
    this.dialogRef.close();
  }

  apiResponse = new ApiResponse;
  onSubmit() {
    if (this.addForm.invalid) {
      return;
    }
     this.showProgressBar(true);
    //use for create the user and save the user in data base
    this.apiService.createUser(this.addForm.value)
      .subscribe(data => {
        this.apiResponse = data;
        this.showProgressBar(false);
        this.router.navigate(['/user/']); 
        this.dialogRef.close();
        if (this.apiResponse.result.isDuplicate === 'Duplicate username') {
          this.toastr.error(Messages.Already_Registered);
        } else {
          this.showProgressBar(false);
          this.toastr.success(Messages.Register_Success);
        }
      });
  }


  ngOnInit() {
    this.roleId = 0;
    this.isValidEmail = false;
  }
  patternValidator(regexp: RegExp): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } => {
      const value = control.value;
      if (value == '' || value == null) {
        return null;
      }
      if (!regexp.test(value)) {
        this.isValidEmail = true;
        return { 'patternInvalid': { regexp } };
      }
      else {
        this.isValidEmail = false;
        return null;
      }
    };
  }
  showProgressBar(progress: boolean) {
    this.progressBar = progress;
    this.cdr.detectChanges();
  }
}


//user for validate the password matching and missmatching
export class RegistrationValidator {
 
  static validate(addForm: FormGroup) {
    let password = addForm.controls.password.value;
    let confirmPassword = addForm.controls.confirmPassword.value;
    if (confirmPassword == '' || confirmPassword == null) {
      return null;
    }
    if (confirmPassword !== password) {
      return {
        doesMatchPassword: true
      };
    }
    return null;

  }

  
}
