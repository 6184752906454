import { Component, OnInit, AfterViewInit, ViewChild, ElementRef } from '@angular/core';
import { Router, ActivatedRoute, ParamMap } from "@angular/router";
import { SearchPropertyService } from '../core/SearchProperty.service';
import { Property } from '../model/property.model';
import { ToastrService } from 'ngx-toastr';
import { FormBuilder, FormGroup, FormControl, FormArray, FormsModule, NgForm, Validators, AbstractControl, ValidatorFn } from '@angular/forms';
import { PropertyService } from '../core/property.service';
import { PropertyGallery } from '../model/property-gallery';
import { PropertyAttachement } from '../model/property-attachement.model';
import { MatDialog } from '@angular/material';
import { User } from '../model/user.model';
import { UserDetails } from '../model/UserDetails.model';
import { Messages } from '../Constants/messages';


@Component({
  selector: 'app-listing',
  templateUrl: './listing.component.html',
  styleUrls: ['./listing.component.css']
})
export class ListingComponent implements OnInit {
  propertyObj: Property;
  propertyGallery: PropertyGallery[];
  propertyAttachment: PropertyAttachement[];
  propertDetailsForm: FormGroup;
  user: User;
  userDetails: UserDetails;
  noValues: boolean;
  totalfiles: Array<File> = [];
  totalFileName = [];
  lengthCheckToaddMore = 0;
  documentFile: File;
  public imagesUrl = [];
  onLoad: boolean = false;

  @ViewChild('contact') contact: ElementRef;
  el: any;
  fix: boolean = false;
  win: any;
  win1: any;
  el1: any;
  elOFF: any;
  nameVar: string;
  areaVar: string;
  priceVar: string;
  minpricevar : string;
  maxpricevar : string;
  typeVar: string;
  storieseVar: string;
  descVar: string;
  realImagePath: string;
  contactNameVar: string;
  contactPhoneVar: string;
  defaultimagePathVar: string;
  companyNameVar: String;
  propertyId: number;
  propertyEmail: string;
  spaceAvailableVar: string;
  minSpaceAvailableVar : string;
  maxSpaceAvailableVar : string;
  cityVar: string;
  latitude: number;
  longitude: number;
  stateVar: string;


  constructor(private router: Router, public dialog: MatDialog, private propertyService: PropertyService, private formBuilder: FormBuilder, private toastr: ToastrService, private route: ActivatedRoute, private searchPropertyService: SearchPropertyService) { }

  totalAttachments: Array<File> = [];
  totalAttachmentsFileName = [];
  attachmentsLimit: number = 0;

  token: string;

  ngOnInit() {
    // this.onLoad = this.header.clicked;
    this.token = window.localStorage.getItem('token');
    this.propertyObj = new Property();
    let propId = +this.route.snapshot.paramMap.get('propertyId');
    if (propId > 0) {
      //Get the property detalis based on property id
      this.searchPropertyService.getPropertyById(propId)
        .subscribe(data => {
          this.propertyObj = data.result;
          console.log(this.propertyObj);
          if (this.propertyObj != null && this.propertyObj != undefined && this.propertyObj.propertyId > 0) {
            this.latitude = JSON.parse(this.propertyObj.latitude);
            this.longitude = JSON.parse(this.propertyObj.longitude);
            this.nameVar = this.propertyObj.propertyName;
            this.priceVar = this.propertyObj.price;
            this.minpricevar = this.propertyObj.minprice;
            this.maxpricevar = this.propertyObj.maxprice;
            this.contactNameVar = this.propertyObj.contactName;
            this.contactPhoneVar = this.propertyObj.phone;
            this.cityVar = this.propertyObj.city;
            this.defaultimagePathVar = this.propertyObj.contactImagePath;
            this.propertyId = this.propertyObj.propertyId;

            this.lengthCheckToaddMore = (this.propertyObj.propertyGallery != null) ? this.propertyObj.propertyGallery.length : 0;
            this.propertyGallery = this.propertyObj.propertyGallery;
            this.attachmentsLimit = (this.propertyObj.propertyAttachement != null) ? this.propertyObj.propertyAttachement.length : 0;
            this.propertyAttachment = this.propertyObj.propertyAttachement;
            this.propertyId = this.propertyObj.propertyId;
            this.propertyEmail = this.propertyObj.contactEmail;
            this.companyNameVar = data.result.companyName;

            if (this.propertyObj.propertyDetails != null || this.propertyObj.propertyDetails != undefined) {
              this.spaceAvailableVar = this.propertyObj.propertyDetails.spaceAvailable;
              this.minSpaceAvailableVar = this.propertyObj.propertyDetails.minSpaceAvailable;
              this.maxSpaceAvailableVar = this.propertyObj.propertyDetails.maxSpaceAvailable;
              this.storieseVar = this.propertyObj.propertyDetails.noOfStories;
              this.descVar = this.propertyObj.propertyDetails.description;
            }
  
            if (this.propertyObj.stateData != null || this.propertyObj.stateData != undefined) {
              this.stateVar = this.propertyObj.stateData.stateName;
            }
            
            if(this.propertyGallery != null) {
              this.propertyGallery.forEach(item => {
                this.realImagePath = item.imagePath;
              });
              if (this.propertyGallery[0] != null) {
                for (let i = 0; i < this.propertyGallery.length; i++) {
                  this.imagesUrl.push(this.propertyGallery[i].imagePath)
                }
              }
              else {
                this.imagesUrl.push(Messages.Sample_Image_Path);
              }
            }
            

          } else {
            console.log("Hello");
            //this.toastr.error(error.error.error_description);
            this.router.navigate(['/']);
          }

          
        }, error => {
          this.toastr.error(error.error.error_description);
        });
    }

    this.propertDetailsForm = this.formBuilder.group({
      userId: [JSON.parse(window.localStorage.getItem('userId'))],
      propertyImages: [null, null],
      defaultImage: [''],
    })

  }



}
