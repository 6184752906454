import { Component, OnInit, Inject } from '@angular/core';
import { DetailedSearch } from '../model/detailed-search.model';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { PropertyService } from '../core/property.service';
import { PropertyType } from '../model/property-type.model';
import { PropertySubType } from '../model/property-sub-type.model';
import { sale } from '../model/sale.model';
import { ValidatorFn, AbstractControl, FormBuilder, FormGroup, FormControl } from '@angular/forms';
import { CurrencyPipe } from '@angular/common';
import { State } from '../model/state.model';

@Component({
  selector: 'app-detailed-search',
  templateUrl: './detailed-search.component.html',
  styleUrls: ['./detailed-search.component.css']
})
export class DetailedSearchComponent implements OnInit {
  search: DetailedSearch;
  propertyTypeList: PropertyType[];
  propertySubTypeList: PropertySubType[];
  searchForm: FormGroup;
  checkValue: Boolean;
  zipcodeCheck: Boolean;
  subtype: Array<number> = [];
  stateList: State[];
  minBuildingSizeCheck: Boolean;
  maxBuildingSizeCheck: Boolean;
  minAvailableSpaceCheck: Boolean;
  maxAvailableSpaceCheck: Boolean;
  minLotSizeCheck: Boolean;
  maxLotSizeCheck: Boolean;
  minCapRateCheck: Boolean;
  maxCapRateCheck: Boolean;
  minYearBuiltCheck: Boolean;
  maxYearBuiltCheck: Boolean;
  labelType: string = 'Sale';
  saleLeaseDropDownList: sale[] = [
    { "id": 1, "name": "Vacant/Owner-User" },
    { "id": 2, "name": "Investment" },
    { "id": 3, "name": "NNN Investment" }
  ];;

  saleDropDownList: sale[] = [
    { "id": 1, "name": "Vacant/Owner-User" },
    { "id": 2, "name": "Investment" },
    { "id": 3, "name": "NNN Investment" }
  ];
  leaseDropDownList: sale[] = [
    { "id": 1, "name": "NNN" },
    { "id": 2, "name": "Modified Net" },
    { "id": 3, "name": "Modified Gross" },
    { "id": 4, "name": "Full Service" }
  ];

  constructor(@Inject(MAT_DIALOG_DATA) public data: searchData, private dialogRef: MatDialogRef<DetailedSearchComponent>, private propertyService: PropertyService,
    private cp: CurrencyPipe) {
    this.search = new DetailedSearch();
    if (this.data.detailedSearch != undefined) {
      this.search = this.data.detailedSearch;
      if (this.search.subTypeId != undefined) {
        this.search.subType = this.search.subTypeId.split(',').map(Number);
      }
    }
    this.search.propertyStatus = this.data.type;
    this.search.propertyTypeId = this.data.propertyTypeId;
    this.search.searchFieldVal = this.data.searchFieldVal;
    this.getPropertyTypes(this.search.propertyStatus);
  }

  ngOnInit() {



    // get state list
    this.propertyService.getStateList()
      .subscribe(data => {
        this.stateList = data.result;
      });

  }

  //change of property status
  getPropertyTypes(propertyStatus) {
    if (propertyStatus != 'lease') {
      this.labelType = 'Sale';
      this.saleLeaseDropDownList = this.saleDropDownList;
      //get property type and sub type list
      this.propertyService.getPropertyTypeAndSubtype()
        .subscribe(data => {
          this.propertyTypeList = data.result;
        });
    } else {
      this.labelType = 'Lease';
      this.saleLeaseDropDownList = this.leaseDropDownList;
      //get property type list
      this.propertyService.getPropertyTypeListBySaleLease(propertyStatus)
        .subscribe(data => {
          this.propertyTypeList = data.result;
        });
    }

  }

  //use noi formate validation
  format(format: string, value) {
    this.validate(value, format);
    if (format === 'minSalePrice' && (this.search.minSalePrice != '' && parseInt(this.search.minSalePrice) > 0)) {
      this.search.minSalePrice = this.search.minSalePrice.replace(',', '');
      this.search.minSalePrice = this.cp.transform(this.search.minSalePrice, 'USD');
    } else if (format == 'maxSalePrice' && (this.search.maxSalePrice != '' && parseInt(this.search.maxSalePrice) > 0)) {
      this.search.maxSalePrice = this.search.maxSalePrice.replace(',', '');
      this.search.maxSalePrice = this.cp.transform(this.search.maxSalePrice, 'USD');
    }

  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  clearForm() {
    this.search = new DetailedSearch();
    this.search.propertyStatus = 'sale';
    this.search.tenancy = '';
    this.search.searchFieldVal = '';
    this.search.city = '';
    this.search.state = 0;
    this.search.zipcode = '';
    this.search.spaceUse = '';
    this.search.tenancy = '';
    this.search.minCapRate = '';
    this.search.maxCapRate = '';
    this.search.minLotSize = '';
    this.search.maxLotSize = '';
    this.search.minSalePrice = '';
    this.search.maxLeasePrice = '';
    this.search.minLeasePrice = '';
    this.search.maxSalePrice = '';
    this.search.maxYearBuilt = '';
    this.search.subTypeId = '0';
    this.search.minAvailableSpace = '';
    this.search.maxAvailableSpace = '';
    this.search.minBuildingSize = '';
    this.search.maxBuildingSize = '';
    this.search.minYearBuilt = '';
    this.search.saletype = '';
    this.search.leasePriceType = '';
  }
  minSalePriceCheck: boolean;
  maxSalePriceCheck: boolean;
  validate(value, type) {
    switch (type) {
      case 'zipcode': this.zipcodeCheck = value; break;
      case 'minBuildingSize': this.minBuildingSizeCheck = value; break;
      case 'maxBuildingSize': this.maxBuildingSizeCheck = value; break;
      case 'minAvailableSpace': this.minAvailableSpaceCheck = value; break;
      case 'maxAvailableSpace': this.maxAvailableSpaceCheck = value; break;
      case 'minLotSize': this.minLotSizeCheck = value; break;
      case 'maxLotSize': this.maxLotSizeCheck = value; break;
      case 'minCapRate': this.minCapRateCheck = value; break;
      case 'maxCapRate': this.maxCapRateCheck = value; break;
      case 'minYearBuilt': this.minYearBuiltCheck = value; break;
      case 'maxYearBuilt': this.maxYearBuiltCheck = value; break;
      case 'minSalePrice': this.minSalePriceCheck = value; break;
      case 'maxSalePrice': this.maxSalePriceCheck = value; break;
    }
    this.checkValue = this.isValid();
  }

  isValid(): Boolean {
    return this.zipcodeCheck || this.maxBuildingSizeCheck || this.minAvailableSpaceCheck || this.maxAvailableSpaceCheck || this.minLotSizeCheck || this.maxLotSizeCheck || this.minCapRateCheck || this.maxCapRateCheck || this.minYearBuiltCheck || this.maxYearBuiltCheck || this.minSalePriceCheck || this.maxSalePriceCheck;
  }
}


export interface searchData {
  type: string;
  propertyTypeId: string;
  searchFieldVal: string;
  detailedSearch: DetailedSearch;
}