import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DashboardMenuComponent } from './dashboard-menu/dashboard-menu.component';
import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';
import { DashboardHeaderComponent } from './dashboard-header/dashboard-header.component';
import { RouterModule } from '@angular/router';
import { ListingHeaderComponent } from '../listing-header/listing-header.component';
import { ListingSidebarComponent } from '../listing-sidebar/listing-sidebar.component';
import { AboutUsComponent } from '../about-us/about-us.component';
import { PrivacyPolicy } from '../terms-and-conditions/terms-and-conditions.component';
import { DashboardFooterComponent } from './dashboard-footer/dashboard-footer.component';
import { SidebarMomentService } from '../core/sidebar/slidebar.service';


@NgModule({
  declarations: [
    DashboardHeaderComponent,
    DashboardMenuComponent,
    AboutUsComponent,
    HeaderComponent,
    FooterComponent,
    ListingHeaderComponent,
    ListingSidebarComponent,
    PrivacyPolicy,
    DashboardFooterComponent

  ],
  imports: [
    CommonModule,
    RouterModule,
  ],
  
  exports: [
    DashboardHeaderComponent,
    DashboardMenuComponent,
    DashboardFooterComponent,
    AboutUsComponent,
    HeaderComponent,
    FooterComponent,
    ListingHeaderComponent,
    ListingSidebarComponent
  ],

  providers: [SidebarMomentService]
})
export class CommonComponentModule { }
