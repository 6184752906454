import { Component, OnInit, Inject, ViewChild, ElementRef } from '@angular/core';
import { Router } from "@angular/router";
import { User } from "../model/user.model";
import { ApiService } from "../core/api.service";
import { ToastrService } from 'ngx-toastr';
import { MatDialogRef, MatDialog } from '@angular/material';
import { FormGroup, FormBuilder, Validators, FormControl, ValidatorFn, AbstractControl } from '@angular/forms';
import { ChangeDetectorRef } from '@angular/core';
import { UserDetails } from '../model/UserDetails.model';
import { PhonePipe } from '../phone-pipe.component';
import { Messages } from '../Constants/messages';
import { PopupMessage } from '../property/property.component';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['../../assets/css/modal-popup.css']
})
export class ProfileComponent implements OnInit {
  updateForm: FormGroup;

  // personalImage: File;

  user: User;
  userDetails: UserDetails;
  noValues: boolean;
  imageSrc: any;
  companyName: string;
  phoneNumber: string;
  progressBar: boolean;
  isValidPhone:boolean;
  fileTypeError: boolean = false;
  roleId:number;

  @ViewChild('firstName') firstName:ElementRef;
  @ViewChild('LastName') LastName:ElementRef;
  @ViewChild('EmailID') EmailID:ElementRef;
  @ViewChild('Phone') Phone:ElementRef;
  @ViewChild('CompanyName') CompanyName:ElementRef;
  
  @ViewChild('licenceNo') LicenseNo:ElementRef;
  FirstVar;
  lastNameVar;
  EmailVar;
  PhoneVar;
  CompanyNameVar;
  LicenseNoVar;
  
  constructor(private phonePipe:PhonePipe, private router: Router, private apiService: ApiService, private toastr: ToastrService, private dialogRef: MatDialogRef<ProfileComponent>, private formBuilder: FormBuilder, private cdr: ChangeDetectorRef, private dialog: MatDialog) {
    this.user = new User();
    this.userDetails = new UserDetails();
    this.roleId = +window.localStorage.getItem('roleId');
    this.updateForm = this.formBuilder.group({
      // personalImage: [''],
      username: new FormControl('', [Validators.required]),
      firstName: ['', Validators.required],
      lastName: ['', Validators.required],
      phoneNumber: new FormControl('', [
        this.patternValidatorPhone(/^\({0,1}\d{3}\){0,1}\s{0,1}\d{3}\-{0,1}\d{4}$/)]),
      companyName: [null,null],
      licenseNo:[null,null]
    });

   
  }


  onXClick(): void {
    this.dialogRef.close();
  }

  ngOnInit() {
    
    setTimeout( ()=>{
      let First = this.firstName.nativeElement.value;
      let Last = this.LastName.nativeElement.value;
      let Email = this.EmailID.nativeElement.value;
      let Phone = this.Phone.nativeElement.value;
      let CompanyName = this.CompanyName.nativeElement.value;

      if(First){
        this.FirstVar = true;
      }
      if(Last){
        this.lastNameVar = true;
      }

      if(Email){
        this.EmailVar = true;
      }

      if(Phone){
        this.PhoneVar = true;
      }
      if(CompanyName){
        this.CompanyNameVar = true;
      }
      if(this.LicenseNoVar){
        this.LicenseNoVar = true;
      }
    }, 200)
    
    if (window.localStorage.getItem('token') == null) {
      this.router.navigate(['login']);
    }
    else {
      this.apiService.getUserByUsername(window.localStorage.getItem('username'))
        .subscribe(data => {
          this.user = data.result;
          if (this.user.userDetails == null) {
            this.noValues = true;
          } else {
            this.noValues = false;
            this.userDetails = data.result.userDetails;
          }
        });
    }

  }

  onSubmitProfile() {
    if (this.updateForm.invalid) {
      return;
    }

    let data = new FormData();
    data.append('User',JSON.stringify(this.user));
    data.append('username', this.updateForm.controls.username.value);
    data.append('firstName', this.updateForm.controls.firstName.value)
    data.append('lastName', this.updateForm.controls.lastName.value);
    data.append('licenseNo', this.updateForm.controls.licenseNo.value);
    // data.append('UserDetails', JSON.stringify(this.userDetails));
    data.append('UserDetails.companyName', this.updateForm.controls.companyName.value);
    data.append('UserDetails.phoneNumber', this.updateForm.controls.phoneNumber.value);
    this.showProgressBar(true);
    this.apiService.updateUser(data).subscribe(data => {
      this.showProgressBar(false);
      this.dialogRef.close();
      this.toastr.success(Messages.UserDetails_Update_Successfull);
    }, error => {
      this.showProgressBar(false);
      this.toastr.error(error.error.error_description);
    }
    );

  }
  showProgressBar(progress: boolean) {
    this.progressBar = progress;
    this.cdr.detectChanges();
  }
  //Personal Image size and type validation
  // setPersonalImage(fileInput:any){
  //     if (fileInput != null) {
  //       let length = fileInput.target.files[0].name.split(".").length;
  //       let fileType:string = fileInput.target.files[0].name.split(".")[length-1].toLowerCase();
  //       let fileItem = fileInput.target.files[0];
  //       if (fileType != 'jpg' && fileType != 'jpeg' && fileType != 'png') {
  //         this.fileTypeError = true;
  //         this.toastr.warning("Image should be of jpg, jpeg or png type.");
  //         return;
  //       } else if (fileItem.size > 5000000) {
  //         this.toastr.warning("Each File should be less than 5 MB of size.");
  //         return;
  //       }
  //       else {
  //         this.personalImage = fileInput.target.files[0];
  //       }
  //     }
  // }

  //use for phone validation
  formatPhoneNumber(phone:string){
    if(phone!=''){
    this.userDetails.phoneNumber =  this.phonePipe.transform(phone,'US');     
  }
  }
  //use phobe pattern validation
  patternValidatorPhone(regexp: RegExp): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } => {
      const value = control.value;
      if (value == '' || value == null) {
        this.isValidPhone = false;
        return null;
      }
      if (!regexp.test(value)) {
        this.isValidPhone = true;
        return { 'patternInvalid': { regexp } };
      }
      else {
        this.isValidPhone = false;

        return null;
      }
    };
  }
  

}

