import { PropertyType } from './property-type.model';
import { PropertyDetails } from './property-details.model';
import { Property } from './property.model';
import { PropertyAttachement } from './property-attachement.model';

export class SearchProperty {
    
    sale:string;
    lease:string;
    saleAndLease:string;
    typeName: String;
    propertyType: PropertyType;

    propertyTypeId:number;
    propertyStatus: string;
    searchFieldVal : string;
    price: string;
    propertyDetails: PropertyDetails;

    
}