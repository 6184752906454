import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { Router, ActivatedRoute } from "@angular/router";
import { ApiService } from "../core/api.service";
import { ToastrService } from 'ngx-toastr';
import { AppComponent } from '../app.component';
import { Messages } from '../Constants/messages';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['../../assets/css/modal-popup.css']
})
export class ChangePasswordComponent implements OnInit {

  invalidLogin: boolean = false;
  username: string;
  showLogin:Boolean =false;
  changePasswordForm: FormGroup;

  constructor(private formBuilder: FormBuilder, private router: Router, private apiService: ApiService, private route: ActivatedRoute, private toastr: ToastrService,private appComp:AppComponent) {
    this.changePasswordForm = this.formBuilder.group({
      username: [''],
      password: ['', Validators.required],
      confirmPassword: ['', Validators.required]
    }, {
        validator: RegistrationValidator.validate.bind(this)
      });
  }


  ngOnInit() {
    this.username = this.route.snapshot.paramMap.get('username');

  }

  onSubmit() {
    this.appComp.showProgressBar(true);
    this.apiService.changePassword(this.changePasswordForm.value)
      .subscribe(data => {
        this.appComp.showProgressBar(false);
        this.showLogin=true;
        this.toastr.success(Messages.Password_Update);
        this.router.navigate(['login']);
      }, error => {
        this.toastr.error(error.error.error.description);
      }
      );
  }
}

export class RegistrationValidator {
  static validate(loginForm: FormGroup) {
    let password = loginForm.controls.password.value;
    let confirmPassword = loginForm.controls.confirmPassword.value;
    if (confirmPassword == '' || confirmPassword == null) {
      return null;
    }
    if (confirmPassword !== password) {
      return {
        doesMatchPassword: true
      };
    }
    return null;

  }
}
