import { PropertyType } from './property-type.model';
import { PropertySubType } from './property-sub-type.model';

export class DetailedSearch {
    
    propertyTypeId;
    propertyType: PropertyType;
    subTypeId:string;
    subType:Array<number>;
    propertySubType: PropertySubType[];
    state:number;
    city:string;
    zipcode:string;
    minSalePrice:string;
    maxSalePrice:string;
    minLeasePrice:string;
    maxLeasePrice:string;
    minBuildingSize:string;
    maxBuildingSize:string;
    minLotSize:string;
    maxLotSize:string;
    minCapRate:string;
    maxCapRate:string;
    minYearBuilt:string;
    maxYearBuilt:string;
    propertyStatus: string;
    searchFieldVal:string;
    tenancy:string;
    spaceUse: string;
    minAvailableSpace:string;
    maxAvailableSpace:string;
    keyword:string;
    saletype:string;
    leasePriceType:string;
}