import { Component, ViewChild, ElementRef} from '@angular/core';
import { Property } from '../model/property.model';
import { PropertyType } from '../model/property-type.model';
import { MatDialogRef } from '@angular/material';


@Component({
  selector: 'app-about-us',
  templateUrl: './about-us.component.html',
  styleUrls: ['./about-us.component.css']
})
export class AboutUsComponent {
  @ViewChild('mainHeader') mainHeader: ElementRef;
  @ViewChild('subHeader') subHeader: ElementRef;
  @ViewChild('getfooter') getfooter:ElementRef;
  propertObjList:Property;
  propertyTypeList: PropertyType[];
  WindowH:any;
  setHeight:any = 237;
  subH:any;
  mainH:number;
  footer:number;

  constructor(private dialogRef:MatDialogRef<AboutUsComponent>) {}

  onXClick(): void {
    this.dialogRef.close();
  }

}
