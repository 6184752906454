import { Component, OnInit, Inject, ViewChild, ElementRef } from '@angular/core';
import { Router } from "@angular/router";
import { User } from "../model/user.model";
import { ApiService } from "../core/api.service";
import { ToastrService } from 'ngx-toastr';
import { MatDialogRef } from '@angular/material';

import { FormGroup, FormBuilder, Validators, FormControl, ValidatorFn, AbstractControl } from '@angular/forms';
import { ChangeDetectorRef } from '@angular/core';
import { HttpClient } from "@angular/common/http";
import { UserDetails } from '../model/UserDetails.model';
import { DomSanitizer } from '@angular/platform-browser';
import { AdminSettingService } from '../core/adminSetting.service';
import { Messages } from '../Constants/messages';

@Component({
  selector: 'app-edit-user',
  templateUrl: './edit-user.component.html',
  // styleUrls: ['./edit-user.component.css']
  styleUrls: ['../../assets/css/modal-popup.css']
})
export class EditUserComponent implements OnInit {
  updateForm: FormGroup;

  user: User;
  userDetails: UserDetails;
  noValues: boolean;
  phoneNumber: string;
  progressBar: boolean;
  isValidPhone: boolean;
  userId: number;

  constructor(private adminSettingService: AdminSettingService, private router: Router, private toastr: ToastrService, private dialogRef: MatDialogRef<EditUserComponent>, private formBuilder: FormBuilder, private cdr: ChangeDetectorRef, private http: HttpClient, private DomSanitizer: DomSanitizer) {
    this.user = new User();
    this.updateForm = this.formBuilder.group({
      username: new FormControl('', [Validators.required]),
      firstName: ['', Validators.required],
      lastName: ['', Validators.required],
      // password:['', Validators.required],
      confirmPassword: [],
      oldPassword: [],
      userId: [''],
      status:['']
    }, {
        validator: RegistrationValidator.validate.bind(this)
      });
  }
  onXClick(): void {
    this.dialogRef.close();
  }

  ngOnInit() {
    this.userId = +localStorage.getItem('userId');
    if (this.userId != 0) {
      this.adminSettingService.getUserById(this.userId)
        .subscribe(data => {
          this.user = data.result;
          console.log(this.user)
        });
    }
  }

  onSubmitUser() {
    if (this.updateForm.invalid) {
      return;
    }

    this.showProgressBar(true);
    if (this.updateForm.value.oldPassword != '' && this.updateForm.value.oldPassword != undefined) {
      this.updateForm.value.password = this.updateForm.value.oldPassword;
    }

    this.adminSettingService.updateUser(this.updateForm.value).subscribe(data => {
      this.showProgressBar(false);
      this.router.navigate(['user']);
      this.dialogRef.close();
      this.toastr.success(Messages.UserDetails_Update_Successfull);
    }, error => {
      this.showProgressBar(false);
      this.toastr.error(error.error.error_description);
    }
    );
  }

  showProgressBar(progress: boolean) {
    this.progressBar = progress;
    this.cdr.detectChanges();
  }

}
//user for validate the password matching and missmatching
export class RegistrationValidator {

  static validate(updateForm: FormGroup) {
    let password = updateForm.controls.oldPassword.value;
    let confirmPassword = updateForm.controls.confirmPassword.value;
    if ((confirmPassword == '' || confirmPassword == null) && (password == '' || password == null)) {
      return null;
    }
    if ((password != '' && confirmPassword == '') || confirmPassword !== password) {
      return {
        doesMatchPassword: true
      };
    }
    return null;

  }


}