import { User } from './user.model';
import { PropertyType } from './property-type.model';
import { PropertySubType } from './property-sub-type.model';
import { PropertyGallery } from './property-gallery';
import { PropertyDetails } from './property-details.model';
import { PropertyAttachement } from './property-attachement.model';
import { State } from './state.model';

export class Property {
  userId: number;
  propertyId: number;
  propertyName: string;
  status: boolean;
  price: string;
  minprice: string;
  maxprice: string;
  propertyStatus: string;
  user: Array<User>;
  propertyTypeId: number;
  propertyType: PropertyType;
  typeId:number;
  publishProperty: boolean;
  totalCount: number;
  saleCount: number;
  leaseCount: number;
  draftCount: number;
  activeCount: number;
  subTypeId:number[];
  propertySubTypeId:string;
  propertyGallery:PropertyGallery[];
  propertyAttachement:PropertyAttachement[];
  latitude:string;
  longitude:string;
  addressOne: string;
  contactImagePath: string;
  addressTwo: string;
  city: string;
  state: string;
  zipCode: string;
  contactName: string;
  phone: string;
  contactEmail:string;
  propertyDetails: PropertyDetails;
  defaultImagePath : string;
  thumbnailImagePath:string;
  leasePriceType:string;
  phoneTwo:string;
  contactEmailTwo:string;
  contactNameTwo:string;
  companyNameOne:string;
  companyNameTwo:string;
  thumbnailCompanyLogoPath: string;
  stateId: number
  stateData: State;
}
