import { Component, OnInit, ViewChild, ElementRef, AfterViewInit } from '@angular/core';
import { Router, ActivatedRoute } from "@angular/router";
import { SearchPropertyService } from '../core/SearchProperty.service';
import { Property } from '../model/property.model';
import { PropertyType } from '../model/property-type.model';
import { ContactComponent } from '../contact/contact.component';
import { MatDialog } from '@angular/material';
import { DetailedSearch } from '../model/detailed-search.model';
import { DetailedSearchComponent } from '../detailed-search/detailed-search.component';
import { HomeComponent } from '../home/home.component';
import { AppComponent } from '../app.component';

@Component({
  selector: 'app-search-list',
  templateUrl: './search-list.component.html',
  styleUrls: ['./search-list.component.css']
})


export class SearchListComponent implements AfterViewInit {
  @ViewChild('mainHeader') mainHeader: ElementRef;
  @ViewChild('subHeader') subHeader: ElementRef;
  @ViewChild('getfooter') getfooter: ElementRef;
  propertObjList: Property;
  propertyTypeList: PropertyType[];
  WindowH: any;
  setHeight: any;
  subH: any;
  mainH: number;
  footer: number;
  PriceShow1: boolean = false;
  BIdg: boolean = false;
  Sort1: boolean = false;
  fullwidth: boolean = false;
  fullwidth1: boolean = true;
  noRecoder: boolean = false;
  propId;
  propStatus: string;
  // serachVal: string;
  distance: Array<number>;
  stateVar: string;
  constructor(private appComp: AppComponent, private home: HomeComponent, private detailedSearch: DetailedSearch, public dialog: MatDialog, private router: Router, private route: ActivatedRoute, private searchPropertyService: SearchPropertyService) {
    document.addEventListener('DOMContentLoaded', function () {
      var elems = document.querySelectorAll('select');

    });

    this.token = window.localStorage.getItem('token');

    this.getURLData();

    if (isNaN(this.propId)) {
      this.propId = 0;
    }
    //for get property types list  this.propStatus
    if (this.propStatus == 'sale' || this.propStatus == 'lease') {
      this.searchPropertyService.getPropertyTypeListBySaleLease(this.propStatus)
        .subscribe(data => {
          this.propertyTypeList = [{ typeId: 0, typeName: "Select Property Type", status: true, propertySubTypeList: null }];//data.result;
          for (let i = 0; i < data.result.length; i++) {
            this.propertyTypeList.push(data.result[i]);
          }
        });
    } else {
      this.searchPropertyService.getPropertyTypeList()
        .subscribe(data => {
          this.propertyTypeList = [{ typeId: 0, typeName: "Select Property Type", status: true, propertySubTypeList: null }];//data.result;
          for (let i = 0; i < data.result.length; i++) {
            this.propertyTypeList.push(data.result[i]);
          }
        });
    }
    this.getData();
  }

  //Getting the data from URL to populate into the detailed search pop up
  getURLData() {
    this.route.queryParams.subscribe(params => {
      this.propStatus = params.propertyStatus;
      this.propId = params.propertyTypeId;
      this.detailedSearch.searchFieldVal = (params.searchFieldVal != undefined) ? params.searchFieldVal : "";
      this.detailedSearch.city = (params.city != undefined) ? params.city : '';
      this.detailedSearch.state = (+params.state != undefined) ? +params.state : 0;
      this.detailedSearch.zipcode = (params.zipcode != undefined) ? params.zipcode : '';
      this.detailedSearch.spaceUse = (params.spaceUse != undefined) ? params.spaceUse : '';
      this.detailedSearch.tenancy = (params.tenancy != undefined) ? params.tenancy : '';
      this.detailedSearch.minCapRate = (params.minCapRate != undefined) ? params.minCapRate : '';
      this.detailedSearch.maxCapRate = (params.maxCapRate != undefined) ? params.maxCapRate : '';
      this.detailedSearch.minLotSize = (params.minLotSize != undefined) ? params.minLotSize : '';
      this.detailedSearch.maxLotSize = (params.maxLotSize != undefined) ? params.maxLotSize : '';
      this.detailedSearch.minSalePrice = (params.minSalePrice != undefined) ? params.minSalePrice : '';
      this.detailedSearch.maxSalePrice = (params.maxSalePrice != undefined) ? params.maxSalePrice : '';
      this.detailedSearch.minLeasePrice = (params.minLeasePrice != undefined) ? params.minLeasePrice : '';
      this.detailedSearch.maxLeasePrice = (params.maxLeasePrice != undefined) ? params.maxLeasePrice : '';
      this.detailedSearch.minYearBuilt = (params.minYearBuilt != undefined) ? params.minYearBuilt : '';
      this.detailedSearch.maxYearBuilt = (params.maxYearBuilt != undefined) ? params.maxYearBuilt : '';
      this.detailedSearch.minYearBuilt = (params.minYearBuilt != undefined) ? params.minYearBuilt : '';
      this.detailedSearch.minBuildingSize = (params.minBuildingSize != undefined) ? params.minBuildingSize : '';
      this.detailedSearch.maxBuildingSize = (params.maxBuildingSize != undefined) ? params.maxBuildingSize : '';
      this.detailedSearch.minAvailableSpace = (params.minAvailableSpace != undefined) ? params.minAvailableSpace : '';
      this.detailedSearch.maxAvailableSpace = (params.maxAvailableSpace != undefined) ? params.maxAvailableSpace : '';
      this.detailedSearch.subTypeId = (params.subTypeId != undefined) ? params.subTypeId : '';
      this.detailedSearch.saletype = (params.saletype != undefined) ? params.saletype : '0';
      this.detailedSearch.leasePriceType = (params.leasePriceType != undefined) ? params.leasePriceType : '';
    });

  }


  switchPage(propertyId) {
    this.router.navigate(['property/details/' + propertyId]);
  }
  openContactUsDialog(): void {
    const dialogRef = this.dialog.open(ContactComponent, {
      width: '1000px',
      height: '600px'
    });
  }
  lat: number;
  lng: number;

  token: string;

  ngAfterViewInit() {
    this.WindowH = (window.innerHeight);
    this.subH = this.subHeader.nativeElement.clientHeight;

    this.setHeight = this.WindowH - (this.subH + this.mainH + 14);
  }
  PriceShow() {
    this.PriceShow1 = !this.PriceShow1;
    this.Sort1 = false;
    this.BIdg = false;
  }
  BIdgSize() {
    this.BIdg = !this.BIdg;
    this.Sort1 = false;
    this.PriceShow1 = false;
  }
  Sort() {
    this.Sort1 = !this.Sort1;
    this.BIdg = false;
    this.PriceShow1 = false;
  }
  printMsg(msg) {
    this.mainH = msg;
  }
  printFooterMsg(msg1) {
    this.footer = msg1;
  }
  fullSlide() {
    this.fullwidth = !this.fullwidth;
    this.fullwidth1 = !this.fullwidth1;
  }

  getData() {
    //for search Property  get the properties 
    this.detailedSearch.searchFieldVal = this.detailedSearch.searchFieldVal != undefined ? this.detailedSearch.searchFieldVal : '';
    this.detailedSearch.saletype = this.detailedSearch.saletype != undefined ? this.detailedSearch.saletype : '0';
    this.detailedSearch.city = this.detailedSearch.city != undefined ? this.detailedSearch.city : '';
    this.detailedSearch.state = (this.detailedSearch.state != undefined && this.detailedSearch.state >= 0) ? this.detailedSearch.state : 0;
    this.detailedSearch.zipcode = this.detailedSearch.zipcode != undefined ? this.detailedSearch.zipcode : '';
    this.detailedSearch.spaceUse = this.detailedSearch.spaceUse != undefined ? this.detailedSearch.spaceUse : '';
    this.detailedSearch.tenancy = this.detailedSearch.tenancy != undefined ? this.detailedSearch.tenancy : '';
    this.detailedSearch.minCapRate = this.detailedSearch.minCapRate != undefined ? this.detailedSearch.minCapRate : '';
    this.detailedSearch.maxCapRate = this.detailedSearch.maxCapRate != undefined ? this.detailedSearch.maxCapRate : '';
    this.detailedSearch.minLotSize = this.detailedSearch.minLotSize != undefined ? this.detailedSearch.minLotSize : '';
    this.detailedSearch.maxLotSize = this.detailedSearch.maxLotSize != undefined ? this.detailedSearch.maxLotSize : '';
    this.detailedSearch.minSalePrice = this.detailedSearch.minSalePrice != undefined ? this.detailedSearch.minSalePrice : '';
    this.detailedSearch.maxSalePrice = this.detailedSearch.maxSalePrice != undefined ? this.detailedSearch.maxSalePrice : '';
    this.detailedSearch.minLeasePrice = this.detailedSearch.minLeasePrice != undefined ? this.detailedSearch.minLeasePrice : '';
    this.detailedSearch.maxLeasePrice = this.detailedSearch.maxLeasePrice != undefined ? this.detailedSearch.maxLeasePrice : '';
    this.detailedSearch.minYearBuilt = this.detailedSearch.minYearBuilt != undefined ? this.detailedSearch.minYearBuilt : '';
    this.detailedSearch.maxYearBuilt = this.detailedSearch.maxYearBuilt != undefined ? this.detailedSearch.maxYearBuilt : '';
    this.detailedSearch.minYearBuilt = this.detailedSearch.minYearBuilt != undefined ? this.detailedSearch.minYearBuilt : '';
    this.detailedSearch.minBuildingSize = this.detailedSearch.minBuildingSize != undefined ? this.detailedSearch.minBuildingSize : '';
    this.detailedSearch.maxBuildingSize = this.detailedSearch.maxBuildingSize != undefined ? this.detailedSearch.maxBuildingSize : '';
    this.detailedSearch.minAvailableSpace = this.detailedSearch.minAvailableSpace != undefined ? this.detailedSearch.minAvailableSpace : '';
    this.detailedSearch.maxAvailableSpace = this.detailedSearch.maxAvailableSpace != undefined ? this.detailedSearch.maxAvailableSpace : '';
    this.detailedSearch.subTypeId = this.detailedSearch.subTypeId != undefined ? this.detailedSearch.subTypeId : '';
    this.detailedSearch.leasePriceType = this.detailedSearch.leasePriceType != undefined ? this.detailedSearch.leasePriceType : '';

    this.appComp.showProgressBar(true);

    let saleTypeVal = this.detailedSearch.saletype;
    if(this.detailedSearch.saletype == 'Vacant/Owner-User') {
      saleTypeVal = "vacant";
    }
    this.searchPropertyService.searchProperty(this.propStatus, this.detailedSearch.searchFieldVal, this.detailedSearch.city, this.detailedSearch.state, this.detailedSearch.zipcode, this.detailedSearch.tenancy, this.detailedSearch.minCapRate, this.detailedSearch.maxCapRate, this.detailedSearch.minLotSize, this.detailedSearch.maxLotSize, this.detailedSearch.minYearBuilt, this.detailedSearch.maxYearBuilt, this.detailedSearch.subTypeId, this.detailedSearch.spaceUse, this.detailedSearch.minSalePrice, this.detailedSearch.maxSalePrice, this.detailedSearch.minLeasePrice, this.detailedSearch.maxLeasePrice, this.detailedSearch.minBuildingSize, this.detailedSearch.maxBuildingSize, this.detailedSearch.minAvailableSpace, this.detailedSearch.maxAvailableSpace, saleTypeVal, this.detailedSearch.leasePriceType)
      .subscribe(data => {
        this.appComp.showProgressBar(false);
        this.propertObjList = data.result;
        if(this.propertObjList.stateData != null || this.propertObjList.stateData !=undefined){
          this.stateVar = this.propertObjList.stateData.stateName;
        }

        
        if (data.result.length > 0) {
          this.lat = (+this.propertObjList[0].latitude);
          this.lng = (+this.propertObjList[0].longitude);
          this.zoom = 9;
          for (let i = 0; i < data.result.length - 1; i++) {
            let lat1 = this.propertObjList[i].latitude;
            let lon1 = this.propertObjList[i].longitude;
            let lat2 = this.propertObjList[i + 1].latitude;
            let lon2 = this.propertObjList[i + 1].longitude;
            let x: any = lat2 - lat1;
            let y: any = lon2 - lon1;
            if (Math.abs(Math.ceil(x)) > 1 || Math.abs(Math.ceil(y)) > 1) {
              this.lat = 39.381266,
                this.lng = -97.922211;
              this.zoom = 4;
            }
          }
        }

        this.noRecoder = true;
      }, error => {
        this.appComp.showProgressBar(false);
      }
      );
    //for get property types list  this.propStatus
    if (this.propStatus == 'sale' || this.propStatus == 'lease') {
      this.searchPropertyService.getPropertyTypeListBySaleLease(this.propStatus)
        .subscribe(data => {
          this.propertyTypeList = [{ typeId: 0, typeName: "Select Property Type", status: true, propertySubTypeList: null }];//data.result;
          for (let i = 0; i < data.result.length; i++) {
            this.propertyTypeList.push(data.result[i]);
          }
        });
    } else {
      this.searchPropertyService.getPropertyTypeList()
        .subscribe(data => {
          this.propertyTypeList = [{ typeId: 0, typeName: "Select Property Type", status: true, propertySubTypeList: null }];//data.result;
          for (let i = 0; i < data.result.length; i++) {
            this.propertyTypeList.push(data.result[i]);
          }
        });
    }

  }

  // google maps zoom level
  zoom: number = 10;


  clickedMarker(label: string, index: number) {
  }

  navigate() {
    let saleTypeVal = this.detailedSearch.saletype;
    if(this.detailedSearch.saletype == 'Vacant/Owner-User') {
      saleTypeVal = "vacant";
    }
    this.router.navigate(['search-list'], { queryParams: { propertyStatus: this.propStatus, searchFieldVal: this.detailedSearch.searchFieldVal, city: this.detailedSearch.city, state: this.detailedSearch.state, zipcode: this.detailedSearch.zipcode, spaceUse: this.detailedSearch.spaceUse, tenancy: this.detailedSearch.tenancy, minCapRate: this.detailedSearch.minCapRate, maxCapRate: this.detailedSearch.maxCapRate, minLotSize: this.detailedSearch.minLotSize, maxLotSize: this.detailedSearch.maxLotSize, minSalePrice: this.detailedSearch.minSalePrice, maxLeasePrice: this.detailedSearch.maxLeasePrice, minLeasePrice: this.detailedSearch.minLeasePrice, maxSalePrice: this.detailedSearch.maxSalePrice, minYearBuilt: this.detailedSearch.minYearBuilt, maxYearBuilt: this.detailedSearch.maxYearBuilt, subTypeId: this.detailedSearch.subTypeId, minAvailableSpace: this.detailedSearch.minAvailableSpace, maxAvailableSpace: this.detailedSearch.maxAvailableSpace, minBuildingSize: this.detailedSearch.minBuildingSize, maxBuildingSize: this.detailedSearch.maxBuildingSize, saletype: saleTypeVal, leasePriceType : this.detailedSearch.leasePriceType } });
    // this.getURLData();
    this.getData();
  }

  openPopup(type, propertyTypeId, searchFieldVal) {
    this.route.queryParams.subscribe(params => {
      this.detailedSearch.propertyStatus = params.propertyStatus;
      if (params.propertyStatus == 'lease') {
        this.detailedSearch.spaceUse = params.spaceUse;
      }
      else {
        this.detailedSearch.propertyTypeId = params.propertyTypeId;
      }
      this.detailedSearch.searchFieldVal = (params.searchFieldVal != undefined) ? params.searchFieldVal : "";
    });
    this.detailedSearch.saletype = (this.detailedSearch.saletype != undefined && this.detailedSearch.saletype == 'vacant') ? "Vacant/Owner-User" : this.detailedSearch.saletype;
    let detailedSearch: DetailedSearch = this.detailedSearch;
    const dialogRef = this.dialog.open(DetailedSearchComponent, {
      width: '80%',
      height: '90%',
      data: { type, propertyTypeId, searchFieldVal, detailedSearch }
    });
    dialogRef.afterClosed().subscribe(result => {

      if (result != undefined || result != null) {
        this.detailedSearch = result;

        this.propStatus = this.detailedSearch.propertyStatus;
        if (this.propStatus == 'lease') {
          this.propId = this.detailedSearch.spaceUse;
        }
        else {
          this.propId = this.detailedSearch.propertyTypeId;
        }

        // this.propId = this.propStatus=='lease'?this.detailedSearch.propertyTypeId:this.detailedSearch.spaceUse;
        // this.serachVal = this.detailedSearch.searchFieldVal;
        if (this.detailedSearch.subType == undefined || this.detailedSearch.subType.length == 0) {
          this.detailedSearch.subTypeId = '0';
        } else {
          this.detailedSearch.subTypeId = this.detailedSearch.subType.toString();
        }

        this.navigate();
      }

    })
  }

}
