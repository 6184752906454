import { Component, OnInit, Inject} from '@angular/core';
import { FormBuilder, FormGroup} from '@angular/forms';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { ApiService } from '../core/api.service';
import { ToastrService } from 'ngx-toastr';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { PropertyService } from "../core/property.service";
import { Property } from '../model/property.model';
import { AddPropertyComponent } from './add-property/add-property.component';
import { SidebarMomentService } from '../core/sidebar/slidebar.service';
import { AppComponent } from '../app.component';
import { Observable } from 'rxjs';
import { Messages } from '../Constants/messages';

@Component({
  selector: 'app-property',
  templateUrl: './property.component.html',
  styleUrls: ['./property.component.css']

})
export class PropertyComponent implements OnInit {
  propertyList: Property;
  name: string;
  type1: Observable<string>;
  type: any='';
  totalCount: number = 0;
  pages: Array<number>;
  pageNo: number = 1;
  sortCategory: string = "property_id-desc";
  sortType: string ;
  maxElements:number = 10;
  propertyStatus: string = "";
  pageData: FormGroup;
  publishProperty: boolean;
  heading: string = "";
  headingCount: string;
  postListingCount:number=0;
  saleCount:number=0;
  leaseCount:number=0;
  saveAsDraftCount:number=0;
  countVal: number;
  sortCriteria:string;
  defaultImage:string;
  isOpen: boolean;
  count1Heading : string = "";
  count2Heading : string = "";
  count1 : number = 0;
  count2 : number = 0
  propertyId: number;
  showListingDropdown = [];
  listingUserId : number = 0;
  
  constructor(private formBuilder: FormBuilder, private router: Router, public dialog: MatDialog, private propertyService: PropertyService, private apiService: ApiService, private toastr: ToastrService, private route: ActivatedRoute, private sidebarMomentService: SidebarMomentService, private appComp: AppComponent) {
    this.route.queryParams.subscribe(params => {
      this.listingUserId = (params['userid'] != undefined) ? params['userid'] : 0;
    });
    
     this.route.params.subscribe(params => {
      if (params['type']) {
          this.type = params['type'];
          this.heading = this.type;
        } else {
          this.heading = "Total";
        }

      this.getPropertyData();
    });
  }
  
  openAddPropertyDialog(): void {
    localStorage.setItem('propId', '0')
    const dialogRef = this.dialog.open(AddPropertyComponent, {
      width: '80%',
      height: '90%'
    });
    dialogRef.afterClosed().subscribe(result => {
      this.route.params.subscribe(params => {
        if (params['type']) {
          this.type = params['type'];
          this.heading = this.type;
        } else {
          this.heading = "Total";
        }
        
        this.getPropertyData();
      });
      
    });
	
  }

  openEditPropertyDialog(propertyId): void {
    localStorage.setItem('propId', propertyId);
    const dialogRef = this.dialog.open(AddPropertyComponent, {
      width: '80%',
      height: '90%',
      data: {propertyId}
    });
    dialogRef.afterClosed().subscribe(result => {
      this.route.params.subscribe(params => {
      
        if (params['type']) {
          this.type = params['type'];
            this.heading = this.type;
          } else {
            this.heading = "Total";
          }
        this.getPropertyData();
      });
      
    });
	
  }

  
  ngOnInit() {
    this.countVal = 10;
    this.showListingDropdown = [10, 20, 30, 40, 50, 100];
    var v = this.sidebarMomentService.change.subscribe(isOpen => {
      this.isOpen = isOpen;
    });

    if (window.localStorage.getItem('token') == null) {
      this.router.navigate(['login']);
    }

    this.propertyList = new Property();
    this.getPropertyData();
  }
  
  getSortedProperty() {
    this.sortCategory = this.sortCriteria;
    this.getPropertyData();
  }
  
  getPageCount(totalCount, maxElements) {
    var a = Math.ceil(totalCount / maxElements);
    if (a > 0) {
      this.pages = Array(a).fill(1).map((x, i) => i + 1);
    }
  }

  getActivePage(page):boolean{
      return (page == this.pageNo)? true:false;
  }

  getPageNo(pages) {
    var pageType = pages.split(":")[1];
    var page = pages.split(":")[0];
    switch(pageType){
      case 'prev':
            this.pageNo = this.pageNo>1?this.pageNo-1:this.pageNo;
            break;
      case 'next':
            this.pageNo = this.pageNo<this.pages.length?this.pageNo+1:this.pageNo;
            break;
      default:
            this.pageNo = page;
    }
    
    
    this.getPropertyData();
  }

  getMaxElements(){
    this.maxElements = this.countVal;
    this.pageNo=1;
    this.getPropertyData();
  }

  getPropertyData() {
    this.appComp.showProgressBar(true);
      this.propertyStatus = this.type;
    this.pageData = this.formBuilder.group({
      pageNo: [JSON.stringify(this.pageNo - 1)],
      sortCategory: [this.sortCategory],
      sortType: [this.sortType],
      propertyStatus: [this.propertyStatus],
      userId: [JSON.parse(window.localStorage.getItem('userId'))],
      maxElements: [JSON.parse(JSON.stringify(this.maxElements))],
      listingUserId : this.listingUserId
    });
    
    //use for get the list by type
    this.propertyService.getListingByType(this.pageData.value)
      .subscribe(data => {
        this.appComp.showProgressBar(false);
        this.propertyList = data.result;
        this.totalCount = 0;
        this.postListingCount = 0;
        this.saveAsDraftCount = 0;
        this.saleCount = 0;
        this.leaseCount = 0;
        if(this.propertyList != null && this.propertyList[0] != null) {
          if (this.type == '' || this.type == null || this.type == 'sale' || this.type == 'lease') {
            this.totalCount = this.propertyList[0].totalCount;
            this.postListingCount = this.propertyList[0].activeCount;
            this.saveAsDraftCount = this.propertyList[0].draftCount;
          } else {
            this.totalCount = this.propertyList[0].totalCount;
            this.saleCount = this.propertyList[0].saleCount;
            this.leaseCount = this.propertyList[0].leaseCount;
          }
        }

        this.count1Heading = (this.type == "active" || this.type == "draft") ? "Sale" : "Active";
        this.count2Heading = (this.type == "active" || this.type == "draft") ? "Lease" : "Draft";

        this.count1 = (this.type == "active" || this.type == "draft") ? this.saleCount : this.postListingCount;
        this.count2 = (this.type == "active" || this.type == "draft") ? this.leaseCount : this.saveAsDraftCount;

        this.getPageCount(this.totalCount, this.maxElements);
      },
        error => {
          if(error.error.error_description.split(":")[0]==="Access token expired")
          {
            this.router.navigate(['login']);
            window.localStorage.removeItem("token");
          }
          this.appComp.showProgressBar(false);
        }
      );
      this.appComp.showProgressBar(false);
  }
  
  //use for delete popup
  deleteProperty(propertyId): void {
    var propertySt = Messages.Property_Delete;
    const dialogRef = this.dialog.open(PopupMessage, {
      width: '600px',
      height: '210px',
      data: {propertyId,propertySt}
    });

    dialogRef.afterClosed().subscribe(result => {
      this.propertyId = result;
      if(this.propertyId!=null){
        //use delete the property from the listing
        this.propertyService.deleteProperty(propertyId)
        .subscribe(data => {
          this.toastr.success(Messages.Property_Delete_Success);
          this.getPropertyData();
        }, error => {
          this.toastr.error(Messages.Error);
        });
      }
    });

  };
  //use for chage the status active to draft and draft to active
  postListing(propertyId) {
    this.appComp.showProgressBar(true);
   this.propertyService.postListing(propertyId).subscribe(data => {
      if (data.message == "success") {
        this.appComp.showProgressBar(false);
        this.getPropertyData();
        this.toastr.success(Messages.Property_Status_Update);
      }
      else {
        this.appComp.showProgressBar(false);
        this.toastr.error(Messages.Error);
      }
    });
  }

  // user open the custom popup for chage the property status
  openDialog(propertyId,publishProperty): void {
    var propertySt = (publishProperty) ? Messages.Property_ActiveToDraft : Messages.Property_DraftToActive;
    const dialogRef = this.dialog.open(PopupMessage, {
      width: '600px',
      height: '210px',
      data: {propertyId, propertySt}
    });

    dialogRef.afterClosed().subscribe(result => {
      this.propertyId = result;
      if(this.propertyId!=null){
        this.postListing(propertyId);
      }
    });
  }
}

export interface DialogData {
  propertyId: number;
  propertySt: string;
}
@Component({
  selector: 'popup-message',
  templateUrl: 'popup-message.html',
  styleUrls: ['./property.component.css']
})
export class PopupMessage {

  constructor(
    public dialogRef: MatDialogRef<PopupMessage>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData) {}

    
  onNoClick(): void {
    this.dialogRef.close();
  }

}


