import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest } from '@angular/common/http';
import { Observable } from "rxjs/index";
import { ApiResponse } from "../model/api.response";
import { environment } from '../../environments/environment';
import { SuperAdmin } from '../model/super-admin.model';
import { User } from '../model/user.model';

@Injectable()
export class AdminSettingService {
    constructor(private http: HttpClient) { }
    apiBaseUrl: string = environment.apiHost;
    //save the super admin details in data base
    saveAdminDetails(superAdmin: SuperAdmin): Observable<ApiResponse> {
        return this.http.post<ApiResponse>(this.apiBaseUrl + 'setting', superAdmin);
    }
   //get setting data
    getSettingData(): Observable<ApiResponse> {
    return this.http.get<ApiResponse>(this.apiBaseUrl + 'setting/get');
    }

    //get the user list
    getUsersList(): Observable<ApiResponse> {
        return this.http.get<ApiResponse>(this.apiBaseUrl + 'setting');
    }
    //delete the user by id
    deleteProperty(userId): Observable<ApiResponse> {
        return this.http.delete<ApiResponse>(this.apiBaseUrl + 'setting/delete/user/' + userId);
    }
    //use for update the user
    updateUser(user: User): Observable<ApiResponse> {
        return this.http.post<ApiResponse>(this.apiBaseUrl + 'setting/update/user', user);
        
    }

    //get user by id for update
    getUserById(userId): Observable<ApiResponse> {
        const token = window.localStorage.getItem('token');
        return this.http.get<ApiResponse>(this.apiBaseUrl + 'setting/' + userId);
    }

     //get the user list
      getUsersListBySort(data): Observable<ApiResponse> {
        let userListUrl = this.apiBaseUrl + 'setting/getUserList';
        return this.http.post<ApiResponse>(userListUrl, data);
    }

}