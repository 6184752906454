import { Injectable, EventEmitter, Output } from '@angular/core';


@Injectable()
export class SidebarMomentService {
  isOpen:boolean= false;
  isOpen1:boolean= false;
  constructor() { }

  @Output() change:EventEmitter<boolean> = new EventEmitter();

  toggle(){
    this.isOpen = !this.isOpen;
    this.change.emit(this.isOpen);
   
  }
}
