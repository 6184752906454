import { Component, OnInit, Input } from '@angular/core';
import { SidebarMomentService } from '../../core/sidebar/slidebar.service';
import { Menus } from '../../model/menus.model';
import { ApiService } from "../../core/api.service";
import { Common } from '../../common';
import { Property } from '../../model/property.model';
import { Router } from '@angular/router';
import { SubMenus } from '../../model/sub-menus.model';

@Component({
  selector: 'app-dashboard-menu',
  templateUrl: './dashboard-menu.component.html',
  styleUrls: ['./dashboard-menu.component.css']
})
export class DashboardMenuComponent implements OnInit {
    
  propertyList:Property;
  menus: Menus;
  qtd:any[] = [];
  map = new Map<string,boolean>();
  isOpen:boolean= false;
  menuCount : number;
  subMenus : SubMenus;
  subMenuCount:number;
  dropDownShow:boolean;
  roleId:number;
  
  constructor( private sidebarMomentService:SidebarMomentService, private apiService : ApiService,private common:Common,private router:Router) { }

  ngOnInit() {
    let v =  this.sidebarMomentService.change.subscribe(isOpen => {
      this.isOpen = isOpen;
    });
    this.roleId = JSON.parse(window.localStorage.getItem("roleId"));
    this.apiService.getMenusByRoleId(this.roleId)
      .subscribe(data => {
        this.menus = data.result;
        this.menuCount = data.result.length;
        this.subMenus = data.result[0].subMenuList;
        this.subMenuCount = data.result[0].subMenuList.length;
      });
  }
  getMap(value) {
    return this.map.get("down"+value);
  }

  menuDrop(v) {
    this.dropDownShow = (this.dropDownShow == true) ? false : true; 
    for(let i=1; i<= this.subMenuCount; i++)
    {
      this.map.set("down"+i,false);
      this.map.set("down"+v,this.dropDownShow);
    }
  }
  getListingByType(type){
    this.router.navigate(['property1/'+JSON.parse(JSON.stringify(type))]);
  }
}
