import { Property } from './property.model';
import { PropertyAttachement } from './property-attachement.model';



export class SaveProperty {
     property:Property;       
	defaultImageName:string;
	editImage:string;
	editAttachment:string;
	galleryId:number;

}
