import { RouterModule, Routes } from '@angular/router';
import { LoginComponent } from "./login/login.component";
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { ChangePasswordComponent } from './change-password/change-password.component';
import { HomeComponent } from './home/home.component';
import { TermsAndConditionsComponent, PrivacyPolicy } from './terms-and-conditions/terms-and-conditions.component';
import { PropertyComponent } from './property/property.component';
import { ProfileComponent } from './profile/profile.component';
import { PasswordChangeComponent } from './password-change/password-change.component';
import { SearchListComponent } from './search-list/search-list.component';
import { ListingComponent } from './listing/listing.component';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { ContactComponent } from './contact/contact.component';
import { AboutUsComponent } from './about-us/about-us.component';
import { AddUserComponent } from './add-user/add-user.component';


const routes: Routes = [
  { path: '', component: HomeComponent },
  { path: 'login', component: LoginComponent },
  { path: 'forgot-password', component: ForgotPasswordComponent },
  { path: 'reset-password/:username', component: ChangePasswordComponent },
  { path: 'terms-and-conditions', component: TermsAndConditionsComponent },
  { path: 'profile', component: ProfileComponent },
  { path: 'password-change', component: PasswordChangeComponent },
  { path: 'search-list', component: SearchListComponent },
  // { path: 'search-list/:propertyStatus/:propertyTypeId/:searchFieldVal', component: SearchListComponent },
  // { path: 'search-list/:propertyStatus/all', component: SearchListComponent },
  // { path: 'search-list/:propertyStatus/all/:searchFieldVal', component: SearchListComponent },
  // { path: 'search-list/:propertyStatus/:propertyTypeId', component: SearchListComponent },
  { path: 'property/details/:propertyId', component: ListingComponent },
  { path: 'contact', component: ContactComponent },
  { path: 'home/:type', component: HomeComponent },
  { path: 'about-us', component: AboutUsComponent },
  { path: 'contact', component: ContactComponent },
  { path: 'privacyPolicy', component: PrivacyPolicy },
  { path: '**', component: PageNotFoundComponent },
  { path: 'add-user', component: AddUserComponent }
];

export const routing = RouterModule.forRoot(routes, { useHash: true });

