import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material';


@Component({
  selector: 'app-terms-and-conditions',
  templateUrl: './terms-and-conditions.component.html',
  styleUrls: ['../../assets/css/modal-popup.css']
})
export class TermsAndConditionsComponent {

  constructor( public dialogRef: MatDialogRef<TermsAndConditionsComponent>) { }

  onXClick(): void {
    this.dialogRef.close();
  }


}

@Component({
  selector: 'app-terms-and-conditions',
  templateUrl: './privacyPolicy.html',
  styleUrls: ['./terms-and-conditions.component.css']
})
export class PrivacyPolicy {

  constructor( public dialogRef: MatDialogRef<PrivacyPolicy>) { }

  onXClick(): void {
    this.dialogRef.close();
  }

}