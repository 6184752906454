import { Component, OnInit, Renderer, ChangeDetectorRef, Inject } from '@angular/core';
import { FormBuilder, FormGroup, FormControl, FormArray, AbstractControl, ValidatorFn, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from "@angular/router";
import { PropertyService } from "../../core/property.service";
import { ToastrService } from 'ngx-toastr';
import { Property } from '../../model/property.model';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { PropertyType } from 'src/app/model/property-type.model';
import { PropertySubType } from 'src/app/model/property-sub-type.model';
import { MatDialog } from '@angular/material';
import { PropertyDetails } from 'src/app/model/property-details.model';
import { PropertyGallery } from '../../model/property-gallery';
import { PropertyAttachement } from '../../model/property-attachement.model';
import { ApiService } from '../../core/api.service';
import { User } from '../../model/user.model';
import { UserDetails } from '../../model/UserDetails.model';
import { SaveProperty } from '../../model/save-property.model';
import { PopupMessage } from '../property.component';
import { sale } from '../../model/sale.model';
import { CurrencyPipe } from '@angular/common';
import { PhonePipe } from '../../phone-pipe.component';
import { Messages } from '../../Constants/messages';
import { State } from 'src/app/model/state.model';


@Component({
  selector: 'app-add-property',
  templateUrl: './add-property.component.html',
  styleUrls: ['./add-property.component.css']
})
export class AddPropertyComponent implements OnInit {
  addPropertyForm: FormGroup;
  propertyObj: Property;
  saveProperty: SaveProperty;
  propertyGallery: PropertyGallery[];
  propertyAttachment: PropertyAttachement[];
  propertyTypeList: PropertyType[];
  propertySubTypeList: PropertySubType[];
  propertyId: number;
  companyStatus: boolean = true;
  formType: string = 'Add';
  isValidYear: boolean = false;
  isValidPhone: boolean = false;
  userId: number;
  propertyDetails: PropertyDetails;
  totalfiles: Array<File> = [];
  totalFileName = [];
  lengthCheckToaddMore = 0;
  documentFile: File;
  selected: Array<string>;
  propertyTypeId: number;
  defaultImageName: string;
  isValidEmail: boolean;
  user: User;
  userDetails: UserDetails;
  editContactDetails: boolean = true;
  newPropertyGallery = [];
  role: string;
  newPropertyAttachment = [];
  progressBar: boolean;
  labelType: string = 'Sale';
  saleLeaseType: string;
  isValidEmailTwo: boolean;
  defaultIndex: number = 0;
  fileTypeError: boolean = false;
  isValidSize: boolean;
  isValidLot: boolean;
  isValidStories: boolean;
  isValidSpace: boolean;
  isValidNoi: boolean;
  isValidCapRate: boolean;
  isValidZipCode: boolean;
  isValidState: boolean;
  isValidCity: boolean;
  totalLogoSize: number;
  images: string = '';
  editImage: string;
  index: number = 0;
  editindex: number = 0;
  stateList: State[];
  isSaleRequired : boolean = false;
  saleLeaseDropDownList: sale[] = [
    // { "id": 0, "name": "Select Sale Type" },
    { "id": 1, "name": "Vacant/Owner-User" },
    { "id": 2, "name": "Investment" },
    { "id": 3, "name": "NNN Investment" }
  ];;

  saleDropDownList: sale[] = [
    // { "id": 0, "name": "Select Sale Type" },
    { "id": 1, "name": "Vacant/Owner-User" },
    { "id": 2, "name": "Investment" },
    { "id": 3, "name": "NNN Investment" }
  ];
  leaseDropDownList: sale[] = [
    // { "id": 0, "name": "Select Lease Type" },
    { "id": 1, "name": "NNN" },
    { "id": 2, "name": "Modified Net" },
    { "id": 3, "name": "Modified Gross" },
    { "id": 4, "name": "Full Service" }
  ];

  onXClick(): void {
    this.dialogRef.close();
  }
  constructor(private phonePipe: PhonePipe, private renderer: Renderer, public dialogRef: MatDialogRef<AddPropertyComponent>, public dialog: MatDialog, private formBuilder: FormBuilder, private router: Router, private propertyService: PropertyService, private toastr: ToastrService, private route: ActivatedRoute, private apiService: ApiService, private cp: CurrencyPipe, private cdr: ChangeDetectorRef) {
    this.propertyObj = new Property();
    this.propertyObj.propertyDetails = new PropertyDetails();
    this.propertyObj.userId = JSON.parse(window.localStorage.getItem('userId'));
    this.propertyObj.publishProperty = false;
    const id: string = route.snapshot.paramMap.get('propertyId');

  }

  ngOnInit() {
    this.propertyId = +localStorage.getItem('propId');
    //get user deails based on user name
    if (this.formType == 'Add') {
      this.apiService.getUserByUsername(window.localStorage.getItem('username'))
        .subscribe(data => {
          this.user = data.result;
          this.userDetails = this.user.userDetails;
          this.role = this.user.role.role;
          this.propertyObj.contactName = this.user.firstName + ' ' + this.user.lastName;
          this.propertyObj.contactEmail = this.user.username;
          if (this.userDetails != null && this.userDetails.phoneNumber.toString() != 'null') {
            this.propertyObj.phone = this.userDetails.phoneNumber.toString();
          }



          if (this.userDetails != null && this.userDetails.companyName != null) {
            this.propertyObj.companyNameOne = this.userDetails.companyName;
          }


        });
    }
    //get property deails based on property id for edit property
    if (this.propertyId > 0) {
      this.propertyService.getPropertyListById(this.propertyId)
        .subscribe(data => {
          this.propertyObj = data.result;
          this.lengthCheckToaddMore = this.propertyObj.propertyGallery.length;
          this.format('price');
          this.format('minprice');
          this.format('maxprice');
          this.format('noi');
          this.propertyGallery = this.propertyObj.propertyGallery;
          this.attachmentsLimit = this.propertyObj.propertyAttachement.length;
          this.propertyAttachment = this.propertyObj.propertyAttachement;
          this.propertyObj.propertyTypeId = this.propertyObj.propertyType.typeId;
          this.propertyObj.stateId = this.propertyObj.stateData.stateId;
          this.saleTypeChangeAction(this.propertyObj.propertyStatus);

          //get property sub type list by type id
          this.propertyService.getPropertySubTypeList(this.propertyObj.propertyType.typeId)
            .subscribe(data => {
              this.propertySubTypeList = data.result;
            });
          this.formType = 'Update';
        }, error => {
          this.toastr.error(error.error.error_description);
        });
    }
    //get property type list
    this.propertyService.getPropertyTypeList()
      .subscribe(data => {
        this.propertyTypeList = data.result;
      });

    // get state list
    this.propertyService.getStateList()
      .subscribe(data => {
        this.stateList = data.result;
      });

    //validate all form fields  
    this.addPropertyForm = this.formBuilder.group({
      propertyName: ['', Validators.required],
      price: new FormControl('', [
        this.validatePrice('saleprice', 'price', /^\$?\-?([1-9]{1}[0-9]{0,2}(\,\d{3})*(\.\d{0,2})?|[1-9]{1}\d{0,}(\.\d{0,2})?|0(\.\d{0,2})?|(\.\d{1,2}))$|^\-?\$?([1-9]{1}\d{0,2}(\,\d{3})*(\.\d{0,2})?|[1-9]{1}\d{0,}(\.\d{0,2})?|0(\.\d{0,2})?|(\.\d{1,2}))$|^\(\$?([1-9]{1}\d{0,2}(\,\d{3})*(\.\d{0,2})?|[1-9]{1}\d{0,}(\.\d{0,2})?|0(\.\d{0,2})?|(\.\d{1,2}))\)$/)]),
      minprice: new FormControl('', [
        this.validatePrice('leaseprice', 'minprice', /^\$?\-?([1-9]{1}[0-9]{0,2}(\,\d{3})*(\.\d{0,2})?|[1-9]{1}\d{0,}(\.\d{0,2})?|0(\.\d{0,2})?|(\.\d{1,2}))$|^\-?\$?([1-9]{1}\d{0,2}(\,\d{3})*(\.\d{0,2})?|[1-9]{1}\d{0,}(\.\d{0,2})?|0(\.\d{0,2})?|(\.\d{1,2}))$|^\(\$?([1-9]{1}\d{0,2}(\,\d{3})*(\.\d{0,2})?|[1-9]{1}\d{0,}(\.\d{0,2})?|0(\.\d{0,2})?|(\.\d{1,2}))\)$/)]),
      maxprice: new FormControl('', [
        this.validatePrice('leaseprice', 'maxprice', /^\$?\-?([1-9]{1}[0-9]{0,2}(\,\d{3})*(\.\d{0,2})?|[1-9]{1}\d{0,}(\.\d{0,2})?|0(\.\d{0,2})?|(\.\d{1,2}))$|^\-?\$?([1-9]{1}\d{0,2}(\,\d{3})*(\.\d{0,2})?|[1-9]{1}\d{0,}(\.\d{0,2})?|0(\.\d{0,2})?|(\.\d{1,2}))$|^\(\$?([1-9]{1}\d{0,2}(\,\d{3})*(\.\d{0,2})?|[1-9]{1}\d{0,}(\.\d{0,2})?|0(\.\d{0,2})?|(\.\d{1,2}))\)$/)]),
      buildingSize: new FormControl('', [
        this.buildingSizeValidate(/^\d{0,7},{0,1}\d{1,3}$/)]),
      lotSize: new FormControl('', [
        this.lotSizeValidate(/^\d{0,7},{0,1}\d{0,3}\.{0,1}\d{0,2}$/)]),
      noOfStories: new FormControl('', [
        this.storiesValidate(/^\d{1,3}$/)]),
      zoning: [''],
      saleType: [''],
      spaceAvailable: new FormControl('', [
        this.spaceValidate(/^\d{0,7},{0,1}\d{1,3}\.{0,1}\d{0,2}$/)]),
      minSpaceAvailable: new FormControl('', [
        this.spaceValidate(/^\d{0,7},{0,1}\d{1,3}\.{0,1}\d{0,2}$/)]),
      maxSpaceAvailable: new FormControl('', [
        this.spaceValidate(/^\d{0,7},{0,1}\d{1,3}\.{0,1}\d{0,2}$/)]),
      tenancy: [''],
      noi: new FormControl('', [
        this.validateNoi(/^\${0,1}((\d{1}\d{0,2},{1}\d{3},{1}\d{3})|(\d{1}\d{0,2},{1}\d{3})|(\d{1,3})\.{0,1}\d{0,2}$)/)]),
      capRate: new FormControl('', [
        this.capRateValidate(/^\d{0,5}\.{0,1}\d{0,2}$/)]),
      propertyStatus: ['', Validators.required],
      area: [''],
      publishProperty: [''],
      addressOne: ['', Validators.required],
      addressTwo: [''],
      city: [''],
      state: [''],
      zipCode: new FormControl('', [
        this.zipCodeValidate(/^\d{5}$/)]),
      description: [''],
      contactName: [''],
      propertyTypeId: ['', Validators.required],
      stateId: ['', Validators.required],
      typeId: [''],
      phone: new FormControl('', [Validators.required,
      this.patternValidatorPhone(/^\({0,1}\d{3}\){0,1}\s{0,1}\d{3}\-{0,1}\d{4}$/)]),
      subTypeName: [''],
      propertySubTypeId: ['', Validators.required],
      subTypeId: [''],
      userId: [JSON.parse(window.localStorage.getItem('userId'))],
      propertyImages: [null, null],
      defaultImage: [''],
      editContactDetails: [''],
      leasePriceType: [''],
      companyNameOne: [''],
      companyNameTwo: [''],
      phoneTwo: new FormControl('', [
        this.patternValidatorPhoneTwo(/^\({0,1}\d{3}\){0,1}\s{0,1}\d{3}\-{0,1}\d{4}$/)]),
      contactNameTwo: [''],
      contactEmail: new FormControl('', [
        this.patternValidatorEmail(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)]),
      yearBuilt: new FormControl('', [
        this.patternValidatorYear(/^[1-2]{1}\d{3}$/)]),
      contactEmailTwo: new FormControl('', [
        this.patternValidatorEmailTwo(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)]),

      items: this.formBuilder.array([this.createUploadDocuments()]),
      attachments: this.formBuilder.array([this.createUploadAttachments()])
    })
  }

  createUploadDocuments(): FormGroup {
    return this.formBuilder.group({
      documentFile: File
    });
  }
  get items(): FormArray {
    return this.addPropertyForm.get('items') as FormArray;
  };

  //user for remove attached image
  removeItem(index: number, galleryId, defaultImage, galleryImage) {
    if (galleryId != 0) {
      if (this.checkValue(defaultImage, galleryImage)) {
        this.toastr.error(Messages.DefaultImage_Error);
        return;
      } else {
        var propertySt = Messages.Delete_Property_Image;
        var propertyId = galleryId;
        const dialogRef = this.dialog.open(PopupMessage, {
          width: '600px',
          height: '210px',
          data: { propertyId, propertySt }
        });
        dialogRef.afterClosed().subscribe(result => {
          var galleryId = result;
          if (galleryId != null) {
            this.propertyService.deleteGallery(galleryId)
              .subscribe(data => {
                this.toastr.success(Messages.ImageRemove_Success);
                this.newPropertyGallery = [];
                for (let k = 0; k < this.propertyGallery.length; k++) {
                  if (k != index) {
                    this.newPropertyGallery.push(this.propertyGallery[k]);
                  }
                }
                this.propertyGallery = [];
                this.propertyGallery = this.newPropertyGallery;
                this.lengthCheckToaddMore = this.lengthCheckToaddMore - 1;
              }, error => {
                this.toastr.error(Messages.Error);
              });
          }

        })
      }
    }
    else {
      this.totalfiles.splice(index);
      this.totalFileName.splice(index);
      this.items.removeAt(index);
      this.lengthCheckToaddMore = this.lengthCheckToaddMore - 1;
    }
  }

  addItem(): void {
    if (this.lengthCheckToaddMore < 4) {
      this.items.insert(this.lengthCheckToaddMore + 1, this.createUploadDocuments())
      this.lengthCheckToaddMore = this.lengthCheckToaddMore + 1;
    }
    else {
      this.toastr.warning(Messages.ImageLimit);
    }
  }


  public fileSelectionEvent(fileInput: any, i, defaultImage: boolean, image: string) {
    if (fileInput.target.files[0]) {
      let length = fileInput.target.files[0].name.split(".").length;
      let fileType: string = fileInput.target.files[0].name.split(".")[length - 1].toLowerCase();
      let fileItem = fileInput.target.files[0];
      //this.totalLogoSize = fileItem.size;
      if (fileType != "jpg" && fileType != "jpeg" && fileType != "png") {
        this.fileTypeError = true;
        this.toastr.warning(Messages.Image_Type_Error);
        return;
      }

      else if (fileItem.size > 5000000) {
        this.toastr.warning(Messages.Size_Error);
        return;
      }
      else {
        this.fileTypeError = false;
        if (this.lengthCheckToaddMore <= 5) {
          if (image != '') {
            let totalfilelen = this.totalfiles.length;
            if (this.totalfiles.length == 0) {
              this.totalfiles[this.index] = fileInput.target.files[0];
              this.totalfiles[this.index]["editId"] = image;
              if (defaultImage == true) {
                this.totalfiles[this.index]["default"] = defaultImage;
              }
              this.index = this.index + 1;
            } else {
              for (let y = 0; y < this.totalfiles.length; y++) {
                if (this.totalfiles[y]["editId"] == image) {
                  this.totalfiles[y] = fileInput.target.files[0];
                  this.totalfiles[y]["editId"] = image;
                  if (defaultImage == true) {
                    this.totalfiles[y]["default"] = defaultImage;
                  }
                  break;
                } else {
                  this.totalfiles[totalfilelen] = fileInput.target.files[0];
                  this.totalfiles[totalfilelen]["editId"] = image;
                  if (defaultImage == true) {
                    this.totalfiles[totalfilelen]["default"] = defaultImage;
                  }
                }
              }
            }
          }
          else if (image == '') {
            if (this.totalfiles.length == 0) {
              this.totalfiles[this.index] = fileInput.target.files[0];
              this.totalfiles[this.index]["id"] = i;
              this.index = this.index + 1;
            } else {
              let totalfilelen1 = this.totalfiles.length;
              for (let w = 0; w < this.totalfiles.length; w++) {
                if (this.totalfiles[w]["id"] == i) {
                  this.totalfiles[w] = fileInput.target.files[0];
                  this.totalfiles[w]["id"] = i;
                  this.index = this.index + 1;
                  break;
                }
                else {
                  this.totalfiles[totalfilelen1] = fileInput.target.files[0];
                  this.totalfiles[totalfilelen1]["id"] = i;
                }
              }
            }
          }
        }
      }

      this.editindex = this.editindex + 1;
    }
  }

  totalAttachments: Array<File> = [];
  totalAttachmentsFileName = [];
  attachmentsLimit: number = 0;
  attachmentIndex: number = 0;
  createUploadAttachments(): FormGroup {
    return this.formBuilder.group({
      documentFile: File
    });
  }
  get attachments(): FormArray {
    return this.addPropertyForm.get('attachments') as FormArray;
  };
  removeAttachment(index: number, attachmentId) {
    if (attachmentId != 0) {
      var propertyId = attachmentId;
      var propertySt = Messages.Delete_Property_Attachment;
      const dialogRef = this.dialog.open(PopupMessage, {
        width: '600px',
        height: '210px',
        data: { propertyId, propertySt }
      });
      dialogRef.afterClosed().subscribe(result => {
        var Id = result;
        if (Id != null) {
          this.propertyService.deleteAttachment(Id)
            .subscribe(data => {
              this.toastr.success(Messages.AttachmentRemove_Success);
              this.newPropertyAttachment = [];
              for (let x = 0; x < this.propertyAttachment.length; x++) {
                if (x != index) {
                  this.newPropertyAttachment.push(this.propertyAttachment[x]);
                }
              }
              this.propertyAttachment = [];
              this.propertyAttachment = this.newPropertyAttachment;
              this.lengthCheckToaddMore = this.lengthCheckToaddMore - 1;

            }, error => {
              this.toastr.error(Messages.Error);
            });
        }
      });
    }
    else {
      this.totalAttachments.splice(index);
      this.totalAttachmentsFileName.splice(index);
      this.attachments.removeAt(index);
      this.attachmentsLimit = this.attachmentsLimit - 1;

    }
  }

  addAttachment(): void {
    if (this.attachmentsLimit < 4) {
      this.attachments.insert(this.attachmentsLimit + 1, this.createUploadDocuments())
      this.attachmentsLimit = this.attachmentsLimit + 1;
    }
    else {
      this.toastr.warning(Messages.AttachmentLimit);
    }
  }
  attachment: string = "";
  public attachmentSelectionEvent(fileInput: any, attachmentId) {

    if (fileInput.target.files[0]) {
      let fileItem = fileInput.target.files[0];
      let length = fileInput.target.files[0].name.split(".").length;
      let fileType: string = fileInput.target.files[0].name.split(".")[length - 1].toLowerCase();
      if (fileItem.size > 10000000) {
        this.toastr.warning(Messages.Attachment_Size_Error);
        return;
      }
      else if (fileType != "pdf") {
        this.fileTypeError = true;
        this.toastr.warning(Messages.Document_Type_Error);
        return;
      }
      else {
        this.fileTypeError = false;
        this.totalAttachments[this.attachmentIndex] = fileInput.target.files[0];
      }
      if (attachmentId != '') {
        this.attachment = this.attachment + attachmentId + '_' + "attachment" + '_' + this.attachmentIndex + ",";
      }
      this.attachmentIndex = this.attachmentIndex + 1;
    }

  }

  onSubmit(formValue: any) {
    if (this.propertyObj.propertyStatus == 'lease' && (this.propertyObj.leasePriceType == null || this.propertyObj.leasePriceType == '')) {
      return false;
    }
    if (this.addPropertyForm.invalid) {
      const controls = this.addPropertyForm.controls;
      for (const name in controls) {
        if (controls[name].invalid) {
          console.log(name);
        }
      }
      return;
    }
    if (this.totalLogoSize > 5000000) {
      this.toastr.warning(Messages.Size_Error);
      return;
    }

    this.propertyObj.userId = JSON.parse(window.localStorage.getItem('userId'));
    this.propertyObj.propertySubTypeId = JSON.stringify(this.propertyObj.subTypeId);
    this.propertyObj.subTypeId = null;
    this.propertyObj.propertyGallery = null;

    let data = new FormData();
    this.totalfiles = this.totalfiles.filter((thing, i, arr) => {
      return arr.indexOf(arr.find(t => t["id"] === thing["id"])) === i;
    });

    if (this.formType == 'Add' && this.defaultIndex == 0 && this.totalfiles[0] != null) {
      this.defaultImageName = this.totalfiles[0].name;
    }

    if (this.formType == 'Update' && this.defaultIndex == 0 && this.totalfiles[0] != null && this.defaultImageName == null && this.propertyGallery == null) {
      this.defaultImageName = this.totalfiles[0].name;
    }

    for (let j = 0; j < this.totalfiles.length; j++) {
      data.append('files', this.totalfiles[j]);
      if (this.totalfiles[j]["editId"] != '' && this.totalfiles[j]["editId"] != undefined) {
        this.images = this.images + this.totalfiles[j]["editId"] + '_' + this.totalfiles[j] + '_' + j + ",";
      }
      if (this.totalfiles[j]["default"] == true) {
        this.defaultImageName = this.totalfiles[j].name;
      }
    }
    for (let j = 0; j < this.totalAttachments.length; j++) {
      data.append('attachments', this.totalAttachments[j]);
    }
    //get the properety type list
    this.propertyService.getPropertyTypeList()
      .subscribe(data => {
        this.propertyTypeList = data.result;
      });

    // get state list
    this.propertyService.getStateList()
      .subscribe(data => {
        this.stateList = data.result;
      });

    this.saveProperty = new SaveProperty();
    this.saveProperty.property = this.propertyObj;
    this.saveProperty.editImage = this.images;
    this.saveProperty.editAttachment = this.attachment;
    this.saveProperty.galleryId = this.galleryId;
    this.saveProperty.defaultImageName = this.defaultImageName;
    // this.saveProperty.attachmentDescription = this.attDescription;


    data.append('saveProperty', JSON.stringify(this.saveProperty));
    this.showProgressBar(true);
    // save and update the property
    this.propertyService.createProperty(data)
      .subscribe(data => {
        if (data.partialText != undefined) {
          var a = JSON.parse(data.partialText);
          this.showProgressBar(false);
          if (a.message == "success") {
            this.toastr.success("Property " + (this.formType == "Add" ? "Added" : "Updated") + " Successfully");
            if (this.router.navigate(['/listings/'])) {
              this.dialogRef.close();
            }
          }
        }
      }, error => {
        this.dialogRef.close();
        this.toastr.error("Error in adding property");
      });
  }
  //use for Email validation
  patternValidatorEmail(regexp: RegExp): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } => {
      const value = control.value;
      if (value == '' || value == null) {
        this.isValidEmail = false;
        return null;
      }
      if (!regexp.test(value)) {
        this.isValidEmail = true;
        return { 'patternInvalid': { regexp } };
      }
      else {
        this.isValidEmail = false;
        return null;
      }
    };
  }
  //use for year validation
  patternValidatorYear(regexp: RegExp): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } => {
      const value = control.value;
      if (value == '' || value == null) {
        this.isValidYear = false;
        return null;
      }
      if (!regexp.test(value)) {
        this.isValidYear = true;
        return { 'patternInvalid': { regexp } };
      }
      else {
        this.isValidYear = false;

        return null;
      }
    };
  }

  //use phone pattern validation
  patternValidatorPhone(regexp: RegExp): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } => {
      const value = control.value;
      if (value == '' || value == null) {
        this.isValidPhone = false;
        return null;
      }
      if (!regexp.test(value)) {
        this.isValidPhone = true;
        return { 'patternInvalid': { regexp } };
      }
      else {
        this.isValidPhone = false;

        return null;
      }
    };
  }
  isValidPhoneTwo: boolean;
  //use phobe pattern validation
  patternValidatorPhoneTwo(regexp: RegExp): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } => {
      const value = control.value;
      if (value == '' || value == null) {
        this.isValidPhoneTwo = false;
        return null;
      }
      if (!regexp.test(value)) {
        this.isValidPhoneTwo = true;
        return { 'patternInvalid': { regexp } };
      }
      else {
        this.isValidPhoneTwo = false;

        return null;
      }
    };
  }
  isValidPrice: boolean;
  //use price validation with comma
  validatePrice(ptopertyType: String, fieldName: String, regexp: RegExp): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } => {
      const value = control.value;
      if (ptopertyType == 'saleprice' && (fieldName == 'minprice' || fieldName == 'maxprice')) {
        return null;
      }
      if (ptopertyType == 'leaseprice' && (fieldName == 'price')) {
        return null;
      }
      if (value == '' || value == null) {
        this.isValidPrice = false;
        return null;
      }
      if (value)
        if (!regexp.test(value)) {
          this.isValidPrice = true;
          return { 'patternInvalid': { regexp } };
        }
        else {
          this.isValidPrice = false;

          return null;
        }
    };
  }
  //user change sub type on change of type
  propertyTypeChangeAction(typeId) {
    let dropDownData = this.propertyTypeList.find((data: any) => data.typeId === typeId);
    if (dropDownData) {
      this.propertyService.getPropertySubTypeList(dropDownData.typeId)
        .subscribe(data => {
          this.propertySubTypeList = data.result;
        });
      if (this.propertySubTypeList != null) {
      }
    } else {
      this.propertySubTypeList = [];
    }

  }


  setDefaultImage(i) {
    this.defaultIndex = i;
    if (this.totalfiles[i] != null) {
      this.defaultImageName = this.totalfiles[i].name;
    }
  }
  galleryId: number = 0;
  setDefaultExistingImage(i1: number, galleryId: number) {
    this.defaultIndex = i1;
    if (this.totalfiles[i1] != null) {
      this.defaultImageName = this.totalfiles[i1].name;
    }
    else {
      this.galleryId = galleryId;
    }
  }

  checkValue(obj1, obj2): boolean {
    if (this.defaultIndex != 0) {
      obj2 = this.defaultIndex;
    }
    return obj1 === obj2;
  }

  isFormValid(): boolean {
    if (this.addPropertyForm.invalid && this.fileTypeError) {
      return true;
    } else {
      return false;
    }
  }
  onNoClick(): void {
    this.dialogRef.close();
  }
  //use noi formate validation
  format(format: string) {
    if (format === 'noi') {
      this.propertyObj.propertyDetails.noi = this.cp.transform(this.propertyObj.propertyDetails.noi, 'USD');
    } else if (format === 'minprice') {
      this.propertyObj.minprice = this.cp.transform(this.propertyObj.minprice, 'USD');
    } else if (format === 'maxprice') {
      this.propertyObj.maxprice = this.cp.transform(this.propertyObj.maxprice, 'USD');
    }
    else {
      this.propertyObj.price = this.cp.transform(this.propertyObj.price, 'USD');
    }
  }
  //use for phone validation
  formatPhoneNumber(phone: string) {
    if (phone != '' && phone.length > 8) {
      this.propertyObj.phone = this.phonePipe.transform(phone, 'US');
    }
  }

  //use for change lable and change the drop down based on sale/lease validation
  saleTypeChangeAction(saleType) {
    if (saleType == 'sale') {
      this.saleLeaseDropDownList = this.saleDropDownList;
      this.labelType = 'Sale';
      this.addPropertyForm.controls['minprice'].clearValidators();
      this.addPropertyForm.controls['minprice'].updateValueAndValidity();
      this.isSaleRequired = true;
      this.propertyService.getPropertyTypeListBySaleLease(saleType)
        .subscribe(data => {
          this.propertyTypeList = data.result;
        });
    } else {
      this.addPropertyForm.controls['price'].clearValidators();
      this.addPropertyForm.controls['price'].updateValueAndValidity();
      this.isSaleRequired = false;
      this.labelType = 'Lease';
      this.saleLeaseDropDownList = this.leaseDropDownList;
      this.propertyService.getPropertyTypeListBySaleLease(saleType)
        .subscribe(data => {
          this.propertyTypeList = data.result;
        });
    }
  }

  //use for building size validation
  buildingSizeValidate(regexp: RegExp): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } => {
      const value = control.value;
      if (value == '' || value == null) {
        this.isValidSize = false;
        return null;
      }
      if (!regexp.test(value)) {
        this.isValidSize = true;
        return { 'patternInvalid': { regexp } };
      }
      else {
        this.isValidSize = false;
        return null;
      }
    };
  }
  //use for lost size validation
  lotSizeValidate(regexp: RegExp): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } => {
      const value = control.value;
      if (value == '' || value == null) {
        this.isValidLot = false;
        return null;
      }
      if (!regexp.test(value)) {
        this.isValidLot = true;
        return { 'patternInvalid': { regexp } };
      }
      else {
        this.isValidLot = false;
        return null;
      }
    };
  }
  //use for no of stories validation
  storiesValidate(regexp: RegExp): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } => {
      const value = control.value;
      if (value == '' || value == null) {
        this.isValidStories = false;
        return null;
      }
      if (!regexp.test(value)) {
        this.isValidStories = true;
        return { 'patternInvalid': { regexp } };
      }
      else {
        this.isValidStories = false;
        return null;
      }
    };
  }
  //use for space available validation
  spaceValidate(regexp: RegExp): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } => {
      const value = control.value;
      if (value == '' || value == null) {
        this.isValidSpace = false;
        return null;
      }
      if (!regexp.test(value)) {
        this.isValidSpace = true;
        return { 'patternInvalid': { regexp } };
      }
      else {
        this.isValidSpace = false;
        return null;
      }
    };
  }
  //use for NOi validation
  validateNoi(regexp: RegExp): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } => {
      const value = control.value;
      if (value == '' || value == null) {
        this.isValidNoi = false;
        return null;
      }
      if (!regexp.test(value)) {
        this.isValidNoi = true;
        return { 'patternInvalid': { regexp } };
      }
      else {
        this.isValidNoi = false;
        return null;
      }
    };
  }
  //use for cap rate validation
  capRateValidate(regexp: RegExp): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } => {
      const value = control.value;
      if (value == '' || value == null) {
        this.isValidCapRate = false;
        return null;
      }
      if (!regexp.test(value) || value > 100) {
        this.isValidCapRate = true;
        return { 'patternInvalid': { regexp } };
      }
      else {
        this.isValidCapRate = false;
        return null;
      }
    };
  }

  //use for zipcode validation
  zipCodeValidate(regexp: RegExp): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } => {
      const value = control.value;
      if (value == '' || value == null) {
        this.isValidZipCode = false;
        return null;
      }
      if (!regexp.test(value)) {
        this.isValidZipCode = true;
        return { 'patternInvalid': { regexp } };
      }
      else {
        this.isValidZipCode = false;
        return null;
      }
    };
  }
  //use for zipcode validation
  stateValidate(regexp: RegExp): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } => {
      const value = control.value;
      if (value == '' || value == null) {
        this.isValidState = false;
        return null;
      }
      if (!regexp.test(value)) {
        this.isValidState = true;
        return { 'patternInvalid': { regexp } };
      }
      else {
        this.isValidState = false;
        return null;
      }
    };
  }

  //use for zipcode validation
  cityValidate(regexp: RegExp): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } => {
      const value = control.value;
      if (value == '' || value == null) {
        this.isValidCity = false;
        return null;
      }
      if (!regexp.test(value)) {
        this.isValidCity = true;
        return { 'patternInvalid': { regexp } };
      }
      else {
        this.isValidCity = false;
        return null;
      }
    };
  }

  // for phone no formatee in US standered
  formatPhoneNumberTwo(phoneTwo: string) {
    if (phoneTwo != '') {
      this.propertyObj.phoneTwo = this.phonePipe.transform(phoneTwo, 'US');
    }
  }

  //use for 2 contact Email validation
  patternValidatorEmailTwo(regexp: RegExp): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } => {
      const value = control.value;
      if (value == '' || value == null) {
        this.isValidEmailTwo = false;
        return null;
      }
      if (!regexp.test(value)) {
        this.isValidEmailTwo = true;
        return { 'patternInvalid': { regexp } };
      }
      else {
        this.isValidEmailTwo = false;
        return null;
      }
    };
  }
  showProgressBar(progress: boolean) {
    this.progressBar = progress;
    this.cdr.detectChanges();
  }

  attachmentDescription(i, id, description) {
    var propertyId = i;
    const dialogRef = this.dialog.open(AddDescription, {
      width: '60%',
      height: '60%',
      data: { propertyId, description }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (this.formType != "Add" && result != undefined) {
        // this.attDescription[i] = result;
        // this.propertyAttachment[i].attachementDescription=result;
        this.propertyService.addAttachmentDescription(id, result).subscribe(data => {
          if (data.message == "success") {
            this.propertyAttachment[i].attachementDescription = result;
            this.toastr.success("Description is added for this attachment");
          }
          else {
            this.toastr.success("Description is not added for this attachment");
          }
        });
      }

    });
  }

  removeCompanyLogo() {
    var propertyId = this.propertyObj.propertyId;
    var propertySt = Messages.Delete_Company_Logo;
    const dialogRef = this.dialog.open(PopupMessage, {
      width: '600px',
      height: '210px',
      data: { propertyId, propertySt }
    });
    dialogRef.afterClosed().subscribe(result => {
      var Id = result;
      if (Id != null) {
        this.propertyService.deleteCompanyLogo(propertyId)
          .subscribe(data => {
            this.toastr.success(Messages.Comapny_Logo_Removed);
            this.propertyObj.thumbnailCompanyLogoPath = null;
          }, error => {
            this.toastr.error(Messages.Error);
          });
      }
    });

  }


}

export interface DialogData {
  propertyId: number;
  description: string;
}
@Component({
  templateUrl: 'add-description.html',
  styleUrls: ['./add-property.component.css']
})
export class AddDescription {
  constructor(
    public dialogRef: MatDialogRef<AddDescription>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData) { }


  onNoClick(): void {
    this.dialogRef.close();
  }

}
