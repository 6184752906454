import { Component,ChangeDetectorRef, OnInit, Injectable } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl, AbstractControl, ValidatorFn } from "@angular/forms";
import { Router } from "@angular/router";
import { ApiService } from "../core/api.service";
import { ToastrService } from 'ngx-toastr';
import { MatDialogRef } from '@angular/material';
import { AppComponent } from '../app.component';
import { Messages } from '../Constants/messages';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.css']

})

export class ForgotPasswordComponent implements OnInit {
  isEmailValid: boolean = false;
  isEmailFormatValid: boolean = false;
  response: any;
  progressBar:boolean;

  constructor(private formBuilder: FormBuilder, private router: Router, private apiService: ApiService, private toastr: ToastrService, private dialogRef: MatDialogRef<ForgotPasswordComponent>,private cdr:ChangeDetectorRef) { }

  forgotForm: FormGroup;

  ngOnInit() {
    this.forgotForm = this.formBuilder.group({
      username: new FormControl('', [
        Validators.required,
        this.patternValidator(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)])
    });
  }
  onXClick(): void {
    this.dialogRef.close();
  }



  showProgressBar(progress: boolean) {
    this.progressBar = progress;
    this.cdr.detectChanges();
  }
  
  onSubmit() {
    this.showProgressBar(true);
    this.apiService.forgotPassword(this.forgotForm.value.username)
      .subscribe(data => {
        this.response = data;
        if (this.response.message == "success") {
          this.isEmailValid = false;
          this.toastr.success(Messages.Forgot_Password_Success);
          this.dialogRef.close();
          this.router.navigate(['login']);
          this.showProgressBar(false);
        }
        else if (this.response.message == "failure") {
          this.isEmailValid = true;
          this.showProgressBar(false);
        }
        else {
          this.toastr.error(this.response);
          this.isEmailValid = false;
          this.showProgressBar(false);
        }
      }, error => {
        this.toastr.error(error.error.error.description);
        this.showProgressBar(false);
      }
      
    );
  }
  patternValidator(regexp: RegExp): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } => {
      const value = control.value;
      if (value == '' || value == null) {
        this.isEmailValid = false;
        this.isEmailFormatValid = false;
        return null;
      }
      if (!regexp.test(value)) {
        this.isEmailFormatValid = true;
        return { 'patternInvalid': { regexp } };
      }
      else {
        this.isEmailFormatValid = false;
        return null;
      }
    };
  }
}
