import { Component,ChangeDetectorRef, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl, AbstractControl, ValidatorFn } from "@angular/forms";
import { ApiService } from "../core/api.service";
import { ToastrService } from 'ngx-toastr';
import { Router, ActivatedRoute, ParamMap } from "@angular/router";
import { SearchPropertyService } from '../core/SearchProperty.service';
import { Property } from '../model/property.model';
import { HttpParams } from '@angular/common/http';
import { MatDialogRef } from '@angular/material';
import { Messages } from '../Constants/messages';
@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.css']
})
export class ContactComponent implements OnInit {
  contactForm: FormGroup;
  isEmailValid: boolean = false;
  isEmailFormatValid: boolean = false;
  progressBar:boolean;
  propertyObj: Property;
  firstName:string;
  lastName:string;
  email:string;
  phone:string;
  comment:string;
 
 
  
  constructor(private dialogRef:MatDialogRef<ContactComponent>,private searchPropertyService: SearchPropertyService,private cdr:ChangeDetectorRef,private formBuilder: FormBuilder, private router: Router, private apiService: ApiService, private toastr: ToastrService) 
  { }

  ngOnInit() {
    this.contactForm = this.formBuilder.group({
      email: new FormControl('', [
        Validators.required,
        this.patternValidator(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)]),
        firstName: ['',Validators.required],
        lastName: ['',Validators.required],
        comment: ['',Validators.required],
        phone: ['',Validators.required]
      });
  }
 
  onSubmit() {
    if(this.contactForm.invalid){
      return;
    }
    const params = new HttpParams()
    .set('firstName', this.contactForm.value.firstName)
    .set('lastName', this.contactForm.value.lastName)
    .set('email', this.contactForm.value.email)
    .set('phone',this.contactForm.value.phone)
    .set('comment', this.contactForm.value.comment);
    
    
    this.showProgressBar(true);
    this.searchPropertyService.submitContactDetails(params)
      .subscribe(data => {
        this.showProgressBar(false);
        if (data.message == "success") {
          this.toastr.success(Messages.Contact_Success);
          this.contactForm.reset(Messages.Contact_Failure);
        }
        else{
          this.toastr.error(Messages.Contact_Failure);
        }
      }
      
    );
  }
  //used for Email validation
  patternValidator(regexp: RegExp): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } => {
      const value = control.value;
      if (value == '' || value == null) {
        this.isEmailFormatValid = false;
        return null;
      }
      if (!regexp.test(value)) {
        this.isEmailFormatValid = true;
        return { 'patternInvalid': { regexp } };
      }
      else {
        this.isEmailFormatValid = false;
        return null;
      }
    };
  }

  
  //used for format phone number to US Format
  formatPhoneNumber(phone:string){
    if(phone!='' && phone.length>8){
    this.phone =  "+1 "+ phone.substr(0, 3) + '-' + phone.substr(3, 3) + '-' + phone.substr(6,4);     
  }
  }

  showProgressBar(progress: boolean) {
    this.progressBar = progress;
    this.cdr.detectChanges();
  }

  onXClick(): void {
    this.dialogRef.close();
  }
}
