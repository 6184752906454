import { UserCompany } from './user-company.model';
import { Role } from './role.model';
import { UserDetails } from './UserDetails.model';

export class User {

  userId: number;
  username: string;
  password: string;
  confirmPassword:String;
  userDetails: UserDetails;
  role: Role;
  firstName: string;
  lastName: string;
  userCompany:Array<UserCompany>;
  licenseNo: string;
  oldPassword : string;

  totalCount: number;
  saleCount: number;
  leaseCount: number;
  draftCount: number;
  activeCount: number;
  //termsAndConditions:boolean;
  ownerCount: number;
  brokerCount: number;
  status: boolean;
}
