import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { FormGroup, FormControl, FormBuilder, ValidatorFn, AbstractControl, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { BillingService } from '../core/billing.service';
import { PhonePipe } from '../phone-pipe.component';

@Component({
  selector: 'app-billing',
  templateUrl: './billing.component.html',
  styleUrls: ['./billing.component.css']
})
export class BillingComponent implements OnInit {
  billingForm: FormGroup;
  isValidPhone: boolean = false;
  isValidPrice: boolean;
  progressBar: boolean;
  pricePerProperty: string;
  isValidZipCode: boolean;
  isValidCardNo: boolean;
  isValidcvvNo: boolean;
  isValidYear: boolean = false;
  isValidMonth: boolean = false;
  fileTypeError: boolean = false;
  constructor(private phonePipe: PhonePipe,private cdr: ChangeDetectorRef, private billingService: BillingService, private formBuilder: FormBuilder, private toastr: ToastrService) { }


  ngOnInit() {
    this.billingForm = this.formBuilder.group({
      cardNo: new FormControl('', [
        this.cardNoValidate(/^\d{0,7},{0,1}\d{0,3}\.{0,1}\d{0,2}\d{0,4}$/)]),
      cardName: ['', Validators.required],
      cvvNo: new FormControl('', [
        this.cvvNoValidate(/^\d{0,7},{0,1}\d{0,3}\.{0,1}\d{0,2}$/)]),
      cardHolderName: ['', Validators.required],
      addressOne: ['', Validators.required],
      addressTwo: [''],
      city: ['', Validators.required],
      state: ['', Validators.required],
      country: ['', Validators.required],
      zipCode: new FormControl('', [
        this.zipCodeValidate(/^\d{5}$/)]),
      phone: new FormControl('', [Validators.required,
      this.patternValidatorPhone(/^\({0,1}\d{3}\){0,1}\s{0,1}\d{3}\-{0,1}\d{4}$/)]),
      month: new FormControl('', [
        this.patternValidatorMonth(/(^0?[1-9]$)|(^1[0-2]$)/)]),
      year: new FormControl('', [
        this.patternValidatorYear(/^[1-2]{1}\d{3}$/)])
    });
  }

  onSubmit() {
    //save the billing details in data base
    this.showProgressBar(true);
    this.billingService.saveBillingDetails(this.billingForm.value).subscribe(data => {
      this.toastr.success("Billing Details Created Succesfully");
      this.showProgressBar(false);
      this.billingForm.reset();
    }, error => {
      this.toastr.error(error.error.error.description);
    });
  }
  showProgressBar(progress: boolean) {
    this.progressBar = progress;
    this.cdr.detectChanges();
  }

  isFormValid(): boolean {
    if (this.billingForm.invalid) {
      return true;
    } else {
      return false;
    }
  }

  phone: string;
  // for phone no formatee in US standered
  formatPhoneNumber(phoneformat: string) {
    this.phone = this.billingForm.controls.phone.value;
    // if (phoneformat != '' && phoneformat.length > 8) {
    //   this.phone = "+1 " + phoneformat.substr(0, 3) + '-' + phoneformat.substr(3, 3) + '-' + phoneformat.substr(6, 4);
    // }
    if(phoneformat!='' && phoneformat.length>8){
      this.phone =  this.phonePipe.transform(phoneformat,'US');     
    }
  }

  //use phone pattern validation
  patternValidatorPhone(regexp: RegExp): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } => {
      const value = control.value;
      if (value == '' || value == null) {
        this.isValidPhone = false;
        return null;
      }
      if (!regexp.test(value)) {
        this.isValidPhone = true;
        return { 'patternInvalid': { regexp } };
      }
      else {
        this.isValidPhone = false;

        return null;
      }
    };
  }
  //use for zipcode validation
  zipCodeValidate(regexp: RegExp): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } => {
      const value = control.value;
      if (value == '' || value == null) {
        this.isValidZipCode = false;
        return null;
      }
      if (!regexp.test(value)) {
        this.isValidZipCode = true;
        return { 'patternInvalid': { regexp } };
      }
      else {
        this.isValidZipCode = false;
        return null;
      }
    };
  }
  
  //use for card no validation
  cardNoValidate(regexp: RegExp): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } => {
      const value = control.value;
      if (value == '' || value == null) {
        this.isValidCardNo = false;
        return null;
      }
      if (!regexp.test(value)) {
        this.isValidCardNo = true;
        return { 'patternInvalid': { regexp } };
      }
      else {
        this.isValidCardNo = false;
        return null;
      }
    };
  }

  //use for lost size validation
  cvvNoValidate(regexp: RegExp): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } => {
      const value = control.value;
      if (value == '' || value == null) {
        this.isValidcvvNo = false;
        return null;
      }
      if (!regexp.test(value)) {
        this.isValidcvvNo = true;
        return { 'patternInvalid': { regexp } };
      }
      else {
        this.isValidcvvNo = false;
        return null;
      }
    };
  }


  //use for year validation
  patternValidatorYear(regexp: RegExp): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } => {
      const value = control.value;
      if (value == '' || value == null) {
        this.isValidYear = false;
        return null;
      }
      if (!regexp.test(value)) {
        this.isValidYear = true;
        return { 'patternInvalid': { regexp } };
      }
      else {
        this.isValidYear = false;

        return null;
      }
    };
  }

  //use for month validation
  patternValidatorMonth(regexp: RegExp): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } => {
      const value = control.value;
      if (value == '' || value == null) {
        this.isValidMonth = false;
        return null;
      }
      if (!regexp.test(value)) {
        this.isValidMonth = true;
        return { 'patternInvalid': { regexp } };
      }
      else {
        this.isValidMonth = false;

        return null;
      }
    };
  }
}
