import { NgModule }       from '@angular/core';
import { PropertyRoutingModule } from './property-routing.module';
import { HTTP_INTERCEPTORS, HttpClientModule } from "@angular/common/http";
import { PropertyComponent, PopupMessage } from './property.component';
import { AddPropertyComponent, AddDescription } from './add-property/add-property.component';
import { CommonComponentModule } from '../common-component/common-component.module';
import { PropertyService } from '../core/property.service';
import { SidebarMomentService } from '../core/sidebar/slidebar.service';
import { TokenInterceptor } from "../core/interceptor";
import { Common } from '../common';
import { FormsModule, ReactiveFormsModule} from '@angular/forms';
import { MatSelectModule ,MatListModule, MatButtonModule, MatFormFieldModule} from '@angular/material'
import { MaterialModule } from '../material.module';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { CommonModule, CurrencyPipe } from '@angular/common';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ToastrModule } from 'ngx-toastr';
import { BrowserModule } from '@angular/platform-browser';
import { SettingsComponent } from '../settings/settings.component';
import { UserComponent } from '../user/user.component';
import { EditUserComponent } from '../edit-user/edit-user.component';
import { BillingComponent } from '../billing/billing.component';


@NgModule({
    imports: [
        PropertyRoutingModule,
        CommonComponentModule,
        CommonModule,
        BrowserAnimationsModule, // Required for custom alerts
        ToastrModule.forRoot(), // Required for custom alerts
        FormsModule,
        ReactiveFormsModule,
        BrowserModule,
        MaterialModule,
        MatButtonModule,
        MatListModule,
        MatProgressSpinnerModule,
        MatSelectModule,
        HttpClientModule,
        MatFormFieldModule,
        MatSelectModule,
        MatListModule,
        MatButtonModule
    ],
    declarations: [
        PropertyComponent,
        AddPropertyComponent,
        PopupMessage,
        SettingsComponent,
        UserComponent,
        EditUserComponent,
        AddDescription,
        BillingComponent
    ],
    providers: [PropertyService, SidebarMomentService, Common, CurrencyPipe, {
        provide: HTTP_INTERCEPTORS,
        useClass: TokenInterceptor,
        multi: true
      }],
      bootstrap: [PopupMessage,AddDescription],
  })
  export class PropertyModule {}
