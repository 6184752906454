import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest } from '@angular/common/http';
import { Observable } from "rxjs/index";
import { ApiResponse } from "../model/api.response";
import { environment } from '../../environments/environment';
import { json } from '@angular-devkit/core';
import { Property } from '../model/property.model';
import { DetailedSearch } from '../model/detailed-search.model';

@Injectable()
export class SearchPropertyService {

  constructor(private http: HttpClient) { }
  apiBaseUrl: string = environment.apiHost;

  //for get property types
  getPropertyTypeList(): Observable<ApiResponse> {
    return this.http.get<ApiResponse>(this.apiBaseUrl + 'property/getPropertyType');
  }

  //for search Property  get the properties 
  searchProperty(propertyStatus: string, searchFieldVal: string,city:string,state:number,zipcode:string,tenancy:string,minCapRate:string, maxCapRate:string, minLotSize:string, maxLotSize:string, minYearBuilt:string, maxYearBuilt:string, subTypeId:string, spaceUse:string, minSalePrice:string, maxSalePrice:string, minLeasePrice:string, maxLeasePrice:string,minBuildingSize:string,maxBuildingSize:string,minAvailableSpace:string,maxAvailableSpace:string, saletype:string, leasePriceType:string): Observable<ApiResponse> {
    return this.http.get<ApiResponse>(this.apiBaseUrl + 'search/searchProperties?propertyStatus=' + propertyStatus + '&searchFieldVal=' + searchFieldVal+'&city='+city+'&state='+state+'&zipcode='+zipcode+'&spaceUse='+spaceUse+'&tenancy='+tenancy+'&minCapRate='+minCapRate+'&maxCapRate='+maxCapRate+'&minLotSize='+minLotSize+'&maxLotSize='+maxLotSize+'&minSalePrice='+minSalePrice+'&maxSalePrice='+maxSalePrice+'&minLeasePrice='+minLeasePrice+'&maxLeasePrice='+maxLeasePrice+'&minYearBuilt='+minYearBuilt+'&maxYearBuilt='+maxYearBuilt+'&minBuildingSize='+minBuildingSize+'&maxBuildingSize='+maxBuildingSize+'&minAvailableSpace='+minAvailableSpace+'&maxAvailableSpace='+maxAvailableSpace+'&subTypeId='+subTypeId+'&saletype='+saletype+'&leasePriceType='+leasePriceType);
  }

  getPropertyById(propertyId): Observable<ApiResponse> {
    return this.http.get<ApiResponse>(this.apiBaseUrl + 'search/getProperty/' + propertyId);
  }

  //get user by user name 
  getUserByUsername(username): Observable<ApiResponse> {
    return this.http.get<ApiResponse>(this.apiBaseUrl + 'search/user/' + username);
  }

  submitContactDetails(contactForm): Observable<ApiResponse> {
    return this.http.post<ApiResponse>(this.apiBaseUrl + 'contact/' , contactForm);
  }

  getPropertyTypeListBySaleLease(saleLeaseType): Observable<ApiResponse> {
    return this.http.get<ApiResponse>(this.apiBaseUrl + 'property/getType/' +saleLeaseType);
  }
}
