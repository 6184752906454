import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { SearchPropertyService } from '../core/SearchProperty.service';
import { PropertyType } from '../model/property-type.model';
import { SearchProperty } from '../model/SearchProperty.model';
import { Property } from '@babel/types/lib';
import { Router, ActivatedRoute } from "@angular/router";
import { MatDialog } from '@angular/material';
import { DetailedSearchComponent } from '../detailed-search/detailed-search.component';
import { DetailedSearch } from '../model/detailed-search.model';
import { PropertyService } from "../core/property.service";

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})

export class HomeComponent implements OnInit {

  searchForm: FormGroup;
  propertyTypeList: PropertyType[];
  searchFieldVal: string;
  serachObj: SearchProperty;
  propertyStatus: string;
  propertyTypeId: number;
  propertObj: Property;
  ngOnInit() {

    this.propertyTypeId = 0;
    window.scroll(10, 10);

    this.getPropertyType();
    this.detailedSearch.subTypeId = '';
    this.detailedSearch.tenancy = '';
    this.searchForm = this.formBuilder.group({
      propertyTypeId: [''],
      propertyStatus: [''],
      searchFieldVal: [''],
      typeName: ['']
    })
  }
  hide: boolean = true;
  hide1: boolean = false;
  hide2: boolean = false;

  constructor(private propertyService: PropertyService, private detailedSearch: DetailedSearch, private dialog: MatDialog, private route: ActivatedRoute, private router: Router, private searchPropertyService: SearchPropertyService, private formBuilder: FormBuilder) {

    this.route.params.subscribe(params => {
      let type = params['type'];
      if (type != null || type != undefined) {
        let typeNo = type == "sale" ? 1 : type == "lease" ? 2 : 3;
        this.Tabs(typeNo, type);
      }
    });
    document.addEventListener('DOMContentLoaded', function () {
      var elems = document.querySelectorAll('select');

    });
    this.serachObj = new SearchProperty();

  }

  Tabs(typeNo, type: string) {
    this.propertyStatus = type;
    switch (typeNo) {
      case 1:
        this.hide = true;
        this.hide1 = false;
        this.hide2 = false;
        break;
      case 2:
        this.hide = false;
        this.hide1 = true;
        this.hide2 = false;
        break;
      default:
        this.hide = false;
        this.hide1 = false;
        this.hide2 = true;
    }
  }

  propertyTypeChangeAction(event: any) {
    if (event.value > 0) {
      this.propertyService.getPropertySubTypeList(event.value)
        .subscribe(data => {
          if (data.result != null && data.result.length > 0) {
            for (let i = 0; i < data.result.length; i++) {
              this.detailedSearch.subTypeId += data.result[i]['subTypeId'] + ",";
            }
            this.detailedSearch.subTypeId = this.detailedSearch.subTypeId.substr(0, this.detailedSearch.subTypeId.length - 1);
          }
        });
    } else {
      this.detailedSearch.subTypeId = '0';
    }
  }

  onSubmitSearch() {
    this.serachObj.propertyStatus = ((this.propertyStatus)) ? (this.propertyStatus) : "sale";
    this.serachObj.propertyTypeId = this.searchForm.get('propertyTypeId').value;
    this.serachObj.searchFieldVal = this.searchForm.get('searchFieldVal').value;

    this.detailedSearch.saletype = (this.detailedSearch.saletype != undefined) ? this.detailedSearch.saletype : '';
    this.detailedSearch.city = (this.detailedSearch.city != undefined) ? this.detailedSearch.city : '';
    this.detailedSearch.state = (this.detailedSearch.state != undefined && this.detailedSearch.state >= 0) ? this.detailedSearch.state : 0;
    this.detailedSearch.zipcode = (this.detailedSearch.zipcode != undefined) ? this.detailedSearch.zipcode : '';
    this.detailedSearch.spaceUse = (this.detailedSearch.spaceUse != undefined) ? this.detailedSearch.spaceUse : '';
    this.detailedSearch.tenancy = (this.detailedSearch.tenancy != undefined) ? this.detailedSearch.tenancy : '';
    this.detailedSearch.minCapRate = (this.detailedSearch.minCapRate != undefined) ? this.detailedSearch.minCapRate : '';
    this.detailedSearch.maxCapRate = (this.detailedSearch.maxCapRate != undefined) ? this.detailedSearch.maxCapRate : '';
    this.detailedSearch.minLotSize = (this.detailedSearch.minLotSize != undefined) ? this.detailedSearch.minLotSize : '';
    this.detailedSearch.maxLotSize = (this.detailedSearch.maxLotSize != undefined) ? this.detailedSearch.maxLotSize : '';
    this.detailedSearch.minSalePrice = (this.detailedSearch.minSalePrice != undefined) ? this.detailedSearch.minSalePrice : '';
    this.detailedSearch.maxSalePrice = (this.detailedSearch.maxSalePrice != undefined) ? this.detailedSearch.maxSalePrice : '';
    this.detailedSearch.minLeasePrice = (this.detailedSearch.minLeasePrice != undefined) ? this.detailedSearch.minLeasePrice : '';
    this.detailedSearch.maxLeasePrice = (this.detailedSearch.maxLeasePrice != undefined) ? this.detailedSearch.maxLeasePrice : '';
    this.detailedSearch.minYearBuilt = (this.detailedSearch.minYearBuilt != undefined) ? this.detailedSearch.minYearBuilt : '';
    this.detailedSearch.maxYearBuilt = (this.detailedSearch.maxYearBuilt != undefined) ? this.detailedSearch.maxYearBuilt : '';
    this.detailedSearch.minBuildingSize = (this.detailedSearch.minBuildingSize != undefined) ? this.detailedSearch.minBuildingSize : '';
    this.detailedSearch.maxBuildingSize = (this.detailedSearch.maxBuildingSize != undefined) ? this.detailedSearch.maxBuildingSize : '';
    this.detailedSearch.minAvailableSpace = (this.detailedSearch.minAvailableSpace != undefined) ? this.detailedSearch.minAvailableSpace : '';
    this.detailedSearch.maxAvailableSpace = (this.detailedSearch.maxAvailableSpace != undefined) ? this.detailedSearch.maxAvailableSpace : '';
    this.detailedSearch.subTypeId = (this.detailedSearch.subTypeId != '') ? this.detailedSearch.subTypeId : '0';
    this.detailedSearch.leasePriceType = (this.detailedSearch.leasePriceType != undefined) ? this.detailedSearch.leasePriceType : '';

    let saleTypeVal = this.detailedSearch.saletype;
    if(this.detailedSearch.saletype == 'Vacant/Owner-User') {
      saleTypeVal = "vacant";
    }
    this.router.navigate(['search-list'], { queryParams: { propertyStatus: this.serachObj.propertyStatus, searchFieldVal: this.serachObj.searchFieldVal, city: this.detailedSearch.city, state: this.detailedSearch.state, zipcode: this.detailedSearch.zipcode, spaceUse: this.detailedSearch.spaceUse, tenancy: this.detailedSearch.tenancy, minCapRate: this.detailedSearch.minCapRate, maxCapRate: this.detailedSearch.maxCapRate, minLotSize: this.detailedSearch.minLotSize, maxLotSize: this.detailedSearch.maxLotSize, minSalePrice: this.detailedSearch.minSalePrice, maxLeasePrice: this.detailedSearch.maxLeasePrice, minLeasePrice: this.detailedSearch.minLeasePrice, maxSalePrice: this.detailedSearch.maxSalePrice, minYearBuilt: this.detailedSearch.minYearBuilt, maxYearBuilt: this.detailedSearch.maxYearBuilt, subTypeId: this.detailedSearch.subTypeId, minAvailableSpace: this.detailedSearch.minAvailableSpace, maxAvailableSpace: this.detailedSearch.maxAvailableSpace, minBuildingSize: this.detailedSearch.minBuildingSize, maxBuildingSize: this.detailedSearch.maxBuildingSize, saletype : saleTypeVal, leasePriceType : this.detailedSearch.leasePriceType } });

  }
  redirect(id, type) {
    this.Tabs(id, type);
    this.getPropertyType();
    this.router.navigate(['home/' + type]);
  }


  openPopup(type) {
    var propertyTypeId = this.propertyTypeId;
    var searchFieldVal = this.searchFieldVal;
    this.detailedSearch = new DetailedSearch();
    this.detailedSearch.tenancy = '';
    this.detailedSearch.state = 0;
    var detailedSearch: DetailedSearch = this.detailedSearch;
    const dialogRef = this.dialog.open(DetailedSearchComponent, {
      width: '80%',
      height: '80%',
      data: { type, propertyTypeId, searchFieldVal, detailedSearch }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result != undefined) {
        this.detailedSearch = result;
        let type = this.detailedSearch.propertyStatus;
        let typeNo = type == "sale" ? 1 : type == "lease" ? 2 : 3;
        this.Tabs(typeNo, type);
        this.propertyTypeId = this.detailedSearch.propertyTypeId;
        if (this.detailedSearch.subType == undefined || this.detailedSearch.subType.length == 0) {
          this.detailedSearch.subTypeId = '0';
        } else {
          this.detailedSearch.subTypeId = this.detailedSearch.subType.toString();
        }

        this.searchFieldVal = this.detailedSearch.searchFieldVal;
        this.onSubmitSearch();
      }

    })
  }

  getPropertyType() {
    if (this.propertyStatus == 'sale' || this.propertyStatus == 'lease') {
      this.searchPropertyService.getPropertyTypeListBySaleLease(this.propertyStatus)
        .subscribe(data => {
          this.propertyTypeList = [{ typeId: 0, typeName: "Select Property Type", status: true, propertySubTypeList: null }];//data.result;
          for (let i = 0; i < data.result.length; i++) {
            this.propertyTypeList.push(data.result[i]);
          }
        });
    } else {
      this.searchPropertyService.getPropertyTypeList()
        .subscribe(data => {
          this.propertyTypeList = [{ typeId: 0, typeName: "Select Property Type", status: true, propertySubTypeList: null }];//data.result;
          for (let i = 0; i < data.result.length; i++) {
            this.propertyTypeList.push(data.result[i]);
          }

        });
    }
  }
}

