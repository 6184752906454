import { Property } from './property.model';

export class PropertyDetails {
    propertyId: number;
    propertyDetailId:number;
    buildingSize: string;
    lotSize: string;
    yearBuilt: string;
    noOfStories: string;
    saleType: string;
    zoning: string;
    spaceAvailable: string;
    minSpaceAvailable : string;
    maxSpaceAvailable : string;
    tenancy: string;
    noi:string;
    capRate: string;
    area: string;
    description:string;
   property:Property;
}
