import { Component, OnInit,ChangeDetectorRef } from '@angular/core';
import { FormGroup, FormControl, FormBuilder,ValidatorFn,AbstractControl } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { AdminSettingService } from '../core/adminSetting.service';
import { CurrencyPipe } from '@angular/common';
import { SuperAdmin } from '../model/super-admin.model';
import { Messages } from '../Constants/messages';

@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.css']
})
export class SettingsComponent implements OnInit {
  superAdminForm: FormGroup;
  isValidPrice: boolean;
  progressBar:boolean;
  pricePerProperty: string;
  superAdminObj: SuperAdmin;
  constructor(private cdr:ChangeDetectorRef,private cp:CurrencyPipe,private adminSettingService:AdminSettingService, private formBuilder: FormBuilder, private router: Router,private route: ActivatedRoute, private toastr: ToastrService) 
    { }

  ngOnInit() {
    this.superAdminObj = new SuperAdmin();
    this.adminSettingService.getSettingData()
    .subscribe(data => {
      this.superAdminObj = data.result;
      this.superAdminObj.pricePerProperty = this.cp.transform(this.superAdminObj.pricePerProperty, 'USD');
    });

    this.superAdminForm = this.formBuilder.group({
      pricePerProperty: new FormControl('', [
        this.validatePrice(/^\${0,1}((\d{1}\d{0,2},{1}\d{3},{1}\d{3})|(\d{1}\d{0,2},{1}\d{3})|(\d{1,3})\.{0,1}\d{0,2}$)/)]),
      promotional: ['']
    });
  }
  onSubmit() {
    //save the super admin details in data base
    this.showProgressBar(true);
      this.adminSettingService.saveAdminDetails(this.superAdminForm.value).subscribe(data => {
        this.toastr.success(Messages.AdminDetails_Success);
        this.showProgressBar(false);
        window.location.reload();
        //this.superAdminForm.reset();
    }, error => {
      this.toastr.error(error.error.error.description);
    });
  }
   //use price formate validation
   format(format:string){
    if(format=='pricePerProperty'){
     this.superAdminObj.pricePerProperty = this.cp.transform(this.superAdminObj.pricePerProperty, 'USD');
    }
  }
  //use price validation with comma
  validatePrice(regexp: RegExp): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } => {
      const value = control.value;
      if (value == '' || value == null) {
        this.isValidPrice = false;
        return null;
      }
      if (!regexp.test(value)) {
        this.isValidPrice = true;
        return { 'patternInvalid': { regexp } };
      }
      else {
        this.isValidPrice = false;

        return null;
      }
    };
  }
  showProgressBar(progress: boolean) {
    this.progressBar = progress;
    this.cdr.detectChanges();
  }
}
