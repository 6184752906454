import { Component,ChangeDetectorRef, OnInit, OnDestroy, ViewChild, ElementRef, AfterViewInit } from '@angular/core';
import { Router } from '@angular/router';
import { SidebarMomentService } from './core/sidebar/slidebar.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit, OnDestroy, AfterViewInit  {
  title = 'CRELPS-REAL';
  progressBar:boolean;
  isOpen:boolean =false;
  HeaderH:any;
  footerH:any;
  WinH:any;
  router: Router;
  setFotter:any;
  dash:any;
  
  constructor(private cdr:ChangeDetectorRef, private sidebarMomentService:SidebarMomentService, private route: Router){
     this.router = route;
  }

  showProgressBar(progress: boolean) {
    this.progressBar = progress;
    this.cdr.detectChanges();
  }
  ngOnInit(){
    var v =  this.sidebarMomentService.change.subscribe(isOpen => {
      this.isOpen = isOpen;
    });
  }
  ngOnDestroy(){
    
  }
  ngAfterViewInit(){
   this.WinH = (window.innerHeight);
   this.setFotter = this.WinH - ( this.HeaderH + this.footerH );
   
  }
  printMsg(msg){
    this.HeaderH = msg;
  }
  printFooterMsg(msg){
    this.footerH = msg;
  }
 
}
