import { Component, OnInit, Input } from '@angular/core';
import { SidebarMomentService } from '../core/sidebar/slidebar.service';
import { Menus } from '../model/menus.model';
import { ApiService } from "../core/api.service";
import { Common } from '../common';

@Component({
  selector: 'app-list-sidebar',
  templateUrl: './listing-sidebar.component.html',
  styleUrls: ['./listing-sidebar.component.css']
})
export class ListingSidebarComponent implements OnInit {
    
  menus: Menus;
  qtd:any[] = [];
  map = new Map();
  isOpen1:boolean= false;
  menuCount : number;
  userId: string;

  constructor(private common:Common, private sidebarMomentService:SidebarMomentService, private apiService : ApiService) { }

  ngOnInit() {

     this.userId = window.localStorage.getItem('userId');

    var v =  this.sidebarMomentService.change.subscribe(isOpen => {
      this.isOpen1 = isOpen;
    });
    this.apiService.getMenusByRoleId(window.localStorage.getItem('roleId'))
      .subscribe(data => {
        this.menus = data.result;
        this.menuCount = data.result.length;
        for(let i=1; i <= this.menuCount; i++)
        {
          this.map.set("down"+i,false);
        }
      });
  }

  getMap(value)
  {
     return this.map.get("down"+value);
  }
  menuDrop(v) {
    for(let i=1; i<= this.menuCount; i++)
    {
      this.map.set("down"+i,false);
      this.map.set("down"+v,true);
    }
  }
}
